import { computed, ref as refVue, watchEffect } from "vue";
import { useStore } from "vuex";
import { ref as refDb, set, onValue, get, child } from "firebase/database";
import database from "../../../../firebase/config";
import useSharedUI from "../../../../shared/composables/useSharedUI";
const useMyTeachers = () => {
  const store = useStore();
  const { sendNotificationTeacherFavorite } = useSharedUI();
  const loadingFavorite = refVue(false);
  const countTeachers = refVue(0);
  const limitTeachers = refVue(12);
  const isChanging = refVue(false);
  const first = refVue(0);
  const search = refVue("");
  const isShowFieldSearch = refVue(false);
  const totalPage = refVue(0);
  const actualPage = refVue(0);
  const totalRecords = refVue(0);
  const isSearch = refVue(false);
  const dataSearch = refVue([]);
  const showFieldSearch = () => {
    if (isShowFieldSearch.value && search.value.length > 0) {
      actualPage.value = 0;
      isSearch.value = true;
      isChanging.value = true;
      let { data } = store.getters["students/teachersAll"];
      dataSearch.value = data.filter(
        (item) =>
          item.name.toLowerCase().includes(search.value.toLowerCase()) ||
          item.last_name.toLowerCase().includes(search.value.toLowerCase())
      );
      totalPage.value = Math.ceil(dataSearch.value.length / 12);
      totalRecords.value = dataSearch.value.length;
      countTeachers.value = 0;
      limitTeachers.value =
        dataSearch.value.length >= 12 ? 12 : dataSearch.value.length;
      isChanging.value = false;
    }
    isShowFieldSearch.value = !isShowFieldSearch.value;
  };

  const onPage = (e) => {
    actualPage.value = e.page;
    isChanging.value = true;
    if (!isSearch.value) {
      let { data } = store.getters["students/teachersAll"];
      countTeachers.value = 12 * e.page;
      let rest = data.length - e.first;
      limitTeachers.value = rest >= 12 ? (e.page + 1) * 12 : data.length;
    } else {
      let data = dataSearch.value;
      countTeachers.value = 12 * e.page;
      let rest = data.length - e.first;
      limitTeachers.value = rest >= 12 ? (e.page + 1) * 12 : data.length;
    }
    isChanging.value = false;
  };

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11
      ? "https://www.youtube.com/embed/" + match[2]
      : null;
  }

  const loadServices = async () => {
    await store.dispatch("students/dashboardStart");
    await store.dispatch("students/getTeachersFavorites");
    store.commit("students/setTeachersAll", { filter: false });
    await store.dispatch("students/getTeachersAll");
    await store.dispatch("students/lastClass");
  };

  const resetSearch = async () => {
    totalPage.value = 0;
    totalRecords.value = 0;
    first.value = 0;
    limitTeachers.value = 12;
    actualPage.value = 0;
    isChanging.value = false;
    countTeachers.value = 0;
    isSearch.value = false;
    isShowFieldSearch.value = false;
    search.value = "";
    await store.dispatch("students/getTeachersAll");
  };

  const toggleInfoTeacherModal = (isOpen, data = {}, type = "normal") => {
    if (isOpen) {
      let favoritesTeachers = store.getters["students/teachersFavorites"];
      const found = favoritesTeachers.data.find(
        (teacher) => teacher.teacher_id === data.teacher_id
      );
      const dbRefLast = refDb(
        database,
        "Teachers/" + data.token_firebase + "/last_status"
      );

      const dbRef = refDb(
        database,
        "Teachers/" + data.token_firebase + "/status"
      );

      onValue(dbRefLast, async (snapshot) => {
        data.status = snapshot.val();
        store.commit("students/teacherModal", { data: data });
      });

      onValue(dbRef, async (snapshot2) => {
        if (snapshot2.val() === "offline") {
          data.status = "offline";
        } else {
          get(child(dbRef, "Teachers/" + data.token_firebase + "/last_status"))
            .then((snapshot3) => {
              if (snapshot3.val() !== "online") {
                data.status = snapshot2.val();
              } else data.status = "online";
            })
            .catch((error) => {
              console.error(error);
            });
        }
        store.commit("students/teacherModal", {
          data: data,
          found: found !== undefined ? true : false,
          type,
        });
      });
    }
    store.commit("students/teacherToggleInfoModal", { isOpen });
  };
  const teacherInfoModalIsOpen = computed(
    () => store.getters["students/teacherIsShowInfo"]
  );

  const lastClass = computed(() => {
    return store.getters["students/lastClass"];
  });
  const teachers = computed(() => {
    return store.getters["students/teachersAll"];
  });

  const addRemoveFavorite = async (id) => {
    loadingFavorite.value = true;
    store.commit("students/deleteFavorite", { isLoading : true });
    const resp = await store.dispatch("students/addRemoveFavorite", { id });
    store.commit("students/deleteFavorite", { isLoading : false , isOpen : false});
    if (resp) {
      const { data } = store.getters["students/teacherModal"];
      sendNotificationTeacherFavorite(data);
    }
    await store.dispatch("shared/resetNotificationStatus");
    await store.dispatch("students/getTeachersFavorites");
    loadingFavorite.value = false;
  };

  const teachersFavorites = computed(() => {
    return store.getters["students/teachersFavorites"];
  });

  const teacherModal = computed(() => {
    return store.getters["students/teacherModal"];
  });

  const confirmDeleteFavorite = computed(() => {
    return store.getters["students/deleteFavorite"];
  })

  const toggleDeleteFavorite = (data = {}) => {
      const { isOpen } = store.getters["students/deleteFavorite"];
      store.commit("students/deleteFavorite", { isOpen: !isOpen , data });
  }

  watchEffect(() => {
    let info = store.getters["students/teachersAll"];
    if (info.data.length > 0) {
      totalPage.value = Math.ceil(info.data.length / 12);
      totalRecords.value = info.data.length;
      info.data.length > 12
        ? (limitTeachers.value = 12)
        : (limitTeachers.value = info.data.length);
    }
  });

  return {
    toggleDeleteFavorite,
    lastClass,
    confirmDeleteFavorite,
    resetSearch,
    teacherModal,
    totalRecords,
    totalPage,
    teachers,
    loadingFavorite,
    teachersFavorites,
    toggleInfoTeacherModal,
    teacherInfoModalIsOpen,
    loadServices,
    dataSearch,
    countTeachers,
    addRemoveFavorite,
    isChanging,
    onPage,
    first,
    isShowFieldSearch,
    showFieldSearch,
    limitTeachers,
    actualPage,
    search,
    isSearch,
    getId,
  };
};

export default useMyTeachers;
