const bgStatus = (text = "processing") => {
  const textLowercase = text.toLowerCase().replaceAll(" ", "-");
  const statusSuccess = ["confirmed", "online"];
  const statusError = ["payment-expired"];
  const statusWarning = ["no-state"];
  const statusInfo = [""];

  if (statusSuccess.includes(textLowercase)) return "bg-feedback-success";
  else if (statusError.includes(textLowercase)) return "bg-feedback-error";
  else if (statusWarning.includes(textLowercase)) return "bg-feedback-warning";
  else if (statusInfo.includes(textLowercase)) return "bg-feedback-info";
  else return "bg-neutro-2";
};

export default bgStatus;
