import { computed, onBeforeMount, ref, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import database from "@/firebase/config";
import { ref as refDb, set, onValue, get, child } from "firebase/database";
import useSharedUI from "../../../../shared/composables/useSharedUI";
import { useStore } from "vuex";
const useInformation = () => {
  const router = useRouter();
  const route = useRoute();
  const params = route.params;
  const isLoadingLevel = ref(false);
  const { sendNotificationUpgradeLevel } = useSharedUI()
  const initialState = {
    level_id: null,
    description: null,
  };
  const infoLevelsStudent = ref({});
  const upgradeLevelPayload = ref(initialState);
  const getStatusStudent = () => {
    const  {data} = store.getters["teachers/getStudent"];
    const dbRefStudent = refDb(
      database,
      "Spanish/" + data.student_info.token_firebase + "/status"
    );

    onValue(dbRefStudent, async (snapshot) => {
      let status = snapshot.val()
      if (snapshot.val() === "offline") {
        status = "offline";
        store.commit("teachers/student", { data: { ...data, status } });

      } else {
        const dbRef = refDb(database);
        get(
          child(
            dbRef,
            "Spanish/" + data.student_info.token_firebase + "/last_status"
          )
        )
          .then((snapshot2) => {
            if (
              snapshot2.val() !== "online" &&
              snapshot2.val() !== "offline"
            ) {
              status = snapshot.val();
            } else status = "online";
            store.commit("teachers/student", { data: { ...data, status } });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }
  const getStudent = async () => {
    const new_params = route.params
    await store.dispatch("teachers/getStudent", { router, id: new_params.id });
    await store.dispatch("teachers/classesTakenThisWeek");
    await store.dispatch("teachers/libraryLevels");
    getStatusStudent()
  };

  const goPageStudents = () => {
    router.push({
      name: "teacher-students",
    });
  };

  const store = useStore();

  const getInfoStudent = () => {
    const student = computed(() => store.getters["teachers/getStudent"]);
    let data = student.value.data;
    let levels_start = {};
    let levels = {};
    let levels_finish = {};
    let i = 0;
    data.subtitles?.forEach((title) => {
      let object = { ["" + i]: title };
      levels_start[title.level_id - 1] = {
        ...levels_start[title.level_id - 1],
        ...object,
      };
      i++;
    });
    Object.entries(levels_start).forEach(([key, value]) => {
      let level_info = {
        do_it: 0,
        total: 0,
        porcentage: 0,
        level_name_short: "",
        level_name: "",
      };
      Object.entries(value).forEach(([key2, value2]) => {
        if (value2.total_level_do === 1) {
          level_info.do_it = level_info.do_it + 1;
        }
        level_info.level_name_short = value2.level_name_short.toLowerCase();
        level_info.level_name = value2.level_name;
        level_info.total++;
      });
      level_info.porcentage = (level_info.do_it * 100) / level_info.total;
      levels[key] = level_info;
    });

    let initial_level = null;
    Object.entries(levels).forEach(([key, value]) => {
      if (initial_level === null) {
        initial_level = {
          ...value,
          initial_level_parent: value.level_name.substr(0, 7),
        };
        levels_finish[key] = initial_level;
      } else {
        if (
          initial_level.initial_level_parent !== value.level_name.substr(0, 7)
        ) {
          initial_level = {
            ...value,
            initial_level_parent: value.level_name.substr(0, 7),
          };
          levels_finish[key] = initial_level;
        }
      }
    });
    infoLevelsStudent.value = levels_finish;
    
  };

  const isShowUpgradeLevelGetter = computed(
    () => store.getters["teachers/studentIsShowUpgradeLevel"]
  );

  const toggleUpgradeLevelModal = (isOpen) => {
    store.commit("teachers/studentToggleUpgradeLevelModal", { isOpen });
  };

  const upgradeLevel = async () => {
    isLoadingLevel.value = true;
    const new_params = route.params;
    let { res } = await store.dispatch("teachers/upgradeLevel", {
      router,
      payload: upgradeLevelPayload.value,
      id: new_params.id,
    });
    isLoadingLevel.value = false;
    if (res.status === "success") {
      const { data } = store.getters["teachers/getStudent"];
      sendNotificationUpgradeLevel(data.student_info.token_firebase)
      toggleUpgradeLevelModal(false);
      getStudent();
      upgradeLevelPayload.value.level_id = null;
      upgradeLevelPayload.value.description = null;
    }
    store.dispatch("shared/resetNotificationStatus");

  };

  const student = computed(() => store.getters["teachers/getStudent"]);
  
  return {
    goPageStudents,
    isShowUpgradeLevelGetter,
    upgradeLevel,
    toggleUpgradeLevelModal,
    getStudent,
    getInfoStudent,
    upgradeLevelPayload,
    student,
    isLoadingLevel,
    infoLevelsStudent,
  };
};
export default useInformation;
