import { computed , watchEffect } from "vue";
import { useStore } from "vuex";
import { ref as refDb, set, onValue, get, child } from "firebase/database";
import database from "@/firebase/config";
import moment from "moment";
const useStudentInformation = () => {
  const store = useStore();

  const studentInfo = computed(
    () => store.getters["students/studentInformation"]
  );

  const studentBilling = computed(() => {
    const currentDate = moment(store.getters["shared/currentTimeTimezone"]).format('YYYY-MM-DD')
    
    return moment(currentDate).isSameOrAfter(moment(studentInfo.value.payment_date_end))
  })

  const teachersAll = computed(() => {
    return store.getters["students/teachersAll"];
  });

  const teachersAllAvailable = computed (() => {
    return store.getters["students/teachersAllAvailable"];
  })

  const teachersFavorites = computed(() => {
    return store.getters["students/teachersFavorites"];
  });

  const classesForDayStudent = computed(() => {
    return store.getters["students/classesTakenThisDay"];
  });

  const userAuthenticated = computed(() => {
    return store.getters["shared/getUserAuthentication"];
  });

  const setStatusTeachers = (teachers) => {
    teachers.forEach((teacher) => {
      const dbRefLast = refDb(
        database,
        "Teachers/" + teacher.token_firebase + "/last_status"
      );

      const dbRef = refDb(
        database,
        "Teachers/" + teacher.token_firebase + "/status"
      );

      onValue(dbRefLast, async (snapshot) => {
        teacher.status = snapshot.val();
      });

      onValue(dbRef, async (snapshot2) => {
        if (snapshot2.val() === "offline") {
          teacher.status = "offline";
        } else {
          get(child(dbRef, "Teachers/" + teacher.token_firebase + "/last_status"))
            .then((snapshot3) => {
              if (snapshot3.val() !== "online") {
                teacher.status = snapshot2.val();
              } else teacher.status = "online";
            })
            .catch((error) => {
              console.error(error);
            });
        }

      });
    })
  }

  watchEffect(()=> {
    const teachersFavorites = store.getters["students/teachersFavorites"]
    if(!teachersFavorites.isLoading){
      setStatusTeachers(teachersFavorites.data)
    }
  })


  return {
    teachersFavorites,
    teachersAllAvailable,
    userAuthenticated,
    studentInfo,
    teachersAll,
    classesForDayStudent,
    studentBilling
  };
};

export default useStudentInformation;
