const headers = [
  { field: "status", name: "Status", sortable: true , filter : true },
  { field: "id_student", name: "Student ID", sortable: false },
  { field: "name", name: "Name(s)", sortable: false },
  { field: "email", name: "Email", sortable: false },
  { field: "level", name: "Level", sortable: true  , filter : true},
  { field: "age", name: "Age", sortable: true  , filter : true},
  { field: "birth", name: "Birthday", sortable: false },
  { field: "gender", name: "Gender", sortable: true , filter : true },
  { field: "time_zone_change", name: "Time zone", sortable: true },
];

const fields = [
  "name",
  "email",
  "tel",
  "age",
  "level",
  "birth",
  "gender",
  "status",
];

const status = {
  online: {
    class: "bg-feedback-success",
    text: "Online",
  },
  absent: {
    class: "bg-feedback-warning",
    text: "Absent",
  },
  busy: {
    class: "bg-feedback-error",
    text: "Busy",
  },
  offline: {
    class: "bg-neutro-2",
    text: "Offline",
  },
};

/* 
  STATUS:
  1 - online
  2 - absent
  3 - busy
  4 - offline
*/

export { headers, status, fields };
