import { computed, ref, onBeforeMount, watchEffect } from "vue";
import { ref as refDb, set, onValue, get, child } from "firebase/database";
import database from "../../../../firebase/config";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import {
  headers,
  status,
  fields,
} from "@/modules/teachers/data/Students/studentsTableData";
import {
  headers as headersComments,
  fields as fieldsComments,
} from "@/modules/teachers/data/Students/commentsTableData";

import { useStore } from "vuex";
import { useRouter } from "vue-router";

const useTable = () => {
  const store = useStore();
  const firstTable = ref(0)
  const rows = ref(10)
  const initialShowTeachersNote = ref(10);
  const seeMoreAll =  (total,view = 'all') =>{
    if(view === 'all')
      initialShowTeachersNote.value =  total;
    else
      initialShowTeachersNote.value =  initialShowTeachersNote.value + 10;
  }
  const onPage = ( { first } ) => {
    firstTable.value =  first
  } 

  onBeforeMount(async () => { 
    await store.dispatch("teachers/getStudents");
    const { data } = store.getters["teachers/getStudents"]
    getStatusStudents(data)
    await store.dispatch("teachers/dashboardStart");
  });

  const students = computed(() => store.getters["teachers/getStudents"]);
  const getStatusStudents = (students) => {
    if (students && students.length > 0) {
      students.forEach((student) => {
        const dbRefStatus = refDb(database, "Spanish/" + student.token_firebase + "/status");
        const dbRefGet = refDb(database)
        onValue(dbRefStatus, (snapshot) => {
          if (snapshot.val() === "online") {
            get(
              child(
                dbRefGet,
                "Spanish/" + student.token_firebase + "/last_status"
              )
            )
              .then((snapshot2) => {
                // console.log(snapshot2.val());
                if (snapshot2.val() !== "online") {
                  student.status = snapshot2.val();
                } else student.status = "online";
              })
              .catch((error) => {
                console.error(error);
              });
          } else student.status = snapshot.val();
        });
      })

      students.forEach((student) => {
        const dbRefLastStatus = refDb(database,"Spanish/" + student.token_firebase + "/last_status");
        const dbRefGet = refDb(database)
        onValue(dbRefLastStatus,(snapshot) => {
          if (
            snapshot.val() !== "online" &&
            snapshot.val() !== "offline"
          ) {
            student.status = snapshot.val();
          } else {
            get(child(dbRefGet, "Spanish/" + student.token_firebase + "/status"))
            .then((snapshot2) => {
              student.status = snapshot2.val();
            })
            .catch((error) => {
              console.error(error);
            });
          }
        })
      });

      store.commit("teachers/students", { isLoading: false, data: students });

    }
  };

  const filtersStudents = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    age: { value: "", matchMode: FilterMatchMode.IN },
    gender: { value: [], matchMode: FilterMatchMode.IN },
    birth: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    level: { value: [], matchMode: FilterMatchMode.IN },
    time_zone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: [], matchMode: FilterMatchMode.IN },
  });


  const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    lesson_start:{ value: "", matchMode: FilterMatchMode.CONTAINS },
    teacher: { value: "", matchMode: FilterMatchMode.CONTAINS },
  });

  
 

  const filterOptions = ref("");
  const showFilterOptions = (e) => {
    filterOptions.value.toggle(e);
  };

  const isShowFieldSearch = ref(false);
  const showFieldSearch = () => {
    isShowFieldSearch.value = !isShowFieldSearch.value;
  };

  const listFilterOptions = headers.filter((i) => i.sortable);
  const currentHeader_ = ref(headers);

  const onColumnToggle = ({ target }, item) => {
    const checked = target.checked;
    const value = target.value;
    

    let _selectedColumns = [];

    if (checked) _selectedColumns = [...currentHeader_.value, item];
    else
      _selectedColumns = currentHeader_.value.filter((c) => c.field !== value);

    currentHeader_.value = _selectedColumns;
  };
  const isChecked = (field) => {
    return currentHeader_.value.some((x) => x.field === field);
  };

  const router = useRouter();
  const goPageStudent = (id) => {
    router.push({
      name: "teacher-student-id",
      params: { id },
    });
  };

  const getListings = computed(() => {
    return store.getters["shared/getListings"];
  })

  return {
    headers: computed(() => currentHeader_.value),
    fields,
    getListings,
    students,
    filters,
    status,
    filterOptions,
    filtersStudents,
    listFilterOptions,
    showFilterOptions,
    onColumnToggle,
    isChecked,
    initialShowTeachersNote,
    seeMoreAll,
    isShowFieldSearch,
    showFieldSearch,
    goPageStudent,
    headersComments,
    fieldsComments,
    firstTable,
    rows,
    onPage
  };
};

export default useTable;
