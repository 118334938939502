<template>
  <div
    class="
      bg-primary-5
      border-r-10
      p-4
      d-flex
      flex-column
      justify-content-center
    "
  >
    <h1 class="text-primary-0 fw-bold fs-3">Hello, {{adminInfo.name}} {{adminInfo.last_name}}</h1>
    <p>Get an overview of the stats</p>
  </div>
</template>

<script>
import useDashboard from "@/modules/admin/composables/Dashboard/useDashboard";
export default {
  setup() {
    const { adminInfo } = useDashboard()
    return {
      adminInfo
    }
  },
}
</script>
