import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { computed , ref } from "vue";

const useDashboardMain = () => {
  const store = useStore();
  const router = useRouter();
  const days =["Su","Mo","Tu", "We", "Th", "Fr", "Sa"]

  const day = new Date(store.getters['shared/currentTimeTimezone']).getDay() + 1;

  const loadServices = async () => {
    await store.dispatch("teachers/dashboardStart");
    await store.dispatch("teachers/classesTakenThisWeek");
    await store.dispatch("teachers/classesTakenThisDay");
  }

  const showProvideFeedback = (item) => {
    store.commit("teachers/studentToggleFeedbackModal", {
      isOpen: true,
      mood: "new",
      data:item
    });
  }

  const getInfoClassesWeek = computed(() => {
    const dashboard = store.getters["teachers/dashboardStart"];
    let classesWeek = dashboard.data.teacher_my_lessons_day;
    let newClasesWeek = new Array(7);
    if(classesWeek !== undefined){
      classesWeek.forEach(classe =>{
        let newClass = classe 
        newClass.day = days[newClass.day_of_week-1];
        newClasesWeek[newClass.day_of_week-1] = newClass
      })
      for(let i = 0 ; i <= 6 ; i++){
        if(newClasesWeek[i] === undefined || !newClasesWeek[i]){
          newClasesWeek[i] = {count:0,day_of_week:i,day:days[i]}
        }
      }
      return newClasesWeek
    }
    return []
  });


  const dashboard = computed(() => {
    return store.getters["teachers/dashboardStart"]
  })

  const classesWeek = computed(() => {
    return store.getters["teachers/teacherClassesTaughtThisWeek"];
  });

  const classesForDay = computed(() => {
    return store.getters["teachers/teacherClassesForDay"]
  })

  return {
    showProvideFeedback,
    dashboard,
    loadServices,
    classesWeek,
    getInfoClassesWeek,
    day,
    classesForDay
  };
};

export default useDashboardMain;
