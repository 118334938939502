<template>
  <div
    class="dashboard-gridd"
    :class="[
      dashboard.data?.my_last_student
        ? 'dashboard-gridd-student-and-classes'
        : 'dashboard-gridd-classes',
    ]"
  >
    <skeleton
      v-if="dashboard.isLoading"
      height="16rem"
      borderRadius="10px"
      class="skeleton-dark container-empty-favorites"
    />
    <div
      class="bg-primary-5 w-100 p-2 border-r-10 p-3"
      v-else-if="dashboard.data?.my_last_student"
    >
      <div class="bg-primary-5 w-100 p-2 border-r-10 p-3">
        <h2 class="text-primary-0 fw-bold fs-4 mb-4">Your latest student</h2>
        <card-small-info-component
          :name="dashboard.data.my_last_student?.name+' '+dashboard.data.my_last_student?.last_name"
          :cardTypes="['view-profile']"
          :total="2"
          :photo="dashboard.data.my_last_student?.photo"
          :goViewProfile="() => goPageStudent(dashboard.data.my_last_student?.student_id)"
          :id="dashboard.data.my_last_student?.id"
        />
        <button
          v-if="!dashboard.data.my_last_student.has_teacher_feedback"
          @click="() => showProvideFeedback(dashboard.data.my_last_student)"
          class="btn text-white bg-secondary-1 bg-hover-secondary-1 py-2 px-3 bl-shadow w-100 mt-3"
        >
          Provide feedback
        </button>
      </div>
    </div>

    <div v-if="classesForDay.isLoading">
      <h5 class="text-primary-1 fw-bold fs-4 mb-4">
        Your scheduled classes for today
      </h5>
      <div class="d-flex gap-4 w-100 h-custom">
        <skeleton
          v-for="i in [1, 2, 3]"
          :key="i"
          height="20rem"
          borderRadius="10px"
          class="skeleton-light"
        />
      </div>
    </div>

    <div
      v-if="classesForDay.data.length == 0 && !classesForDay.isLoading"
      class="pe-5 w-100 today-classes"
      style="width: calc(100% - 15rem)"
    >
      <h5 class="text-primary-1 fw-bold fs-4 mb-4">
        Your scheduled classes for today
      </h5>
      <div class="d-flex gap-4 w-100 h-custom pb-4">
        <div
          v-for="i in [1, 2, 3]"
          :key="i"
          class="bg-primary-5 d-flex align-items-center border-r-10 text-center w-100 px-4 py-3"
        >
          <h1 class="text-primary-1 fw-bold fs-4 px-3">
            No class has been scheduled for you
          </h1>
        </div>
      </div>
    </div>
    <div
      v-if="classesForDay.data.length > 0 && !classesForDay.isLoading"
      class="pe-5 w-100"
      style="width: calc(100% - 15rem)"
    >
      <Carousel
        :value="classesForDay.data"
        :numVisible="3"
        :numScroll="1"
        contentClass="carousel-custom-2 px-2 mt-4"
        indicatorsContentClass="d-none"
      >
        <template #header>
          <h5 class="text-primary-1 fw-bold fs-4 mb-0">
            Your scheduled classes for today
          </h5>
        </template>
        <template #item="slotProps">
          <card-scheduled-class-component :info="slotProps.data" />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import CardSmallInfoComponent from "@/shared/components/Cards/CardSmallInfoComponent";
import CardScheduledClassComponent from "@/shared/components/Cards/CardScheduledClassComponent";
import useDashboard from "@/modules/teachers/composables/Dashboard/useDashboardMain";
import useTable from "@/modules/teachers/composables/Student/useTable";
export default {
  components: {
    CardSmallInfoComponent,
    CardScheduledClassComponent,
  },
  setup() {
    const { dashboard, classesForDay , showProvideFeedback } = useDashboard();
    const { goPageStudent } = useTable();
    return {
      dashboard,
      classesForDay,
      goPageStudent,
      showProvideFeedback
    };
  },
};
</script>

<style lang="scss">
.dashboard-gridd {
  display: grid;
  gap: 1rem;
  grid-column: 1 / 3;
  &-student-and-classes {
    grid-template-columns: 15rem calc(100% - 15rem);
  }
  &-classes {
    grid-template-columns: 1fr;
  }
}
.title {
  grid-column: 1 / 2;
  grid-row: 1;
  text-align: left;
}
.h-custom {
  height: 85%;
}
</style>
