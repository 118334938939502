const bgColor = {
  id: "bgColor",
  beforeDraw: (chart, options) => {
    const { ctx, width, height } = chart;
    ctx.fillStyle = "#dee7f7";
    ctx.fillRect(0, 0, width, height);
    ctx.restore();
  },
};


export default bgColor;