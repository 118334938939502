const insertPercentage = ({ number = 10 }) => {
  const tagPercentage = document.querySelector(".current-counter");
  if (tagPercentage) {
    tagPercentage.classList.add("d-none");
    const percentage = document.createElement("span");
    percentage.classList.add("current-percentage");
    percentage.textContent = number + "%";
    tagPercentage.before(percentage);
  }
};

export default insertPercentage;
