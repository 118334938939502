<template>
  <div class="bg-primary-5 border-r-10 w-100 dashboard-grid-teachers" 
  :class="!studentInfo.completed_info ? 'grid-full-width' : ''">

    <div class="p-3" v-if="teachersAllAvailable.isLoading">
      <h1 class="text-primary-0 fs-4 fw-bold mb-2">Know our teachers</h1>
      <skeleton borderRadius="10px" height="20rem" class="skeleton-dark" />
    </div>
    <div class="p-3" v-else-if="!teachersAllAvailable.isLoading && teachersAllAvailable.data.filter(teacher => teacher.see).length > 0">
      <Carousel :value="teachersAllAvailable.data.slice(0, 7).filter( teacher => teacher.see ) " :numVisible="1" :autoplayInterval="3000"
        containerClass="bg-white border-r-10 p-1 py-3 order-1 slider-teachers-card" contentClass="slider-teachers"
        indicatorsContentClass="slider-teachers-indicators">
        <template #item="slotProps">
          <card-teacher-component 
            :name="slotProps.data.name"
            :last_name="slotProps.data.last_name"
            :isCompleted="userAuthenticated.completed_info" :teacher_id="slotProps.data.id"  
            :teacher_status="slotProps.data.status"
            :photo="slotProps.data.photo"
            />
        </template>
      </Carousel>

    </div>
    <div class="p-3 h-100" v-else>
      <div class="h-100">
        <h1 class="text-primary-0 fs-4 fw-bold mb-2">Know our teachers</h1>
        <div class="bg-white p-4 bl-shadow border-r-10 w-100 d-flex align-items-center text-center justify-content-center" style="height:336.8px">
          <p>Sorry, there aren't any teachers available at this moment.</p>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { onMounted } from "vue";
import useStudentInformation from "@/modules/students/composables/useStudentInformation";
import CardTeacherComponent from "./CardTeacherComponent";
import insertTextIndicators from "../../utils/insertTextIndicators";

export default {
  components: {
    CardTeacherComponent,
  },
  setup() {
    onMounted(() => {
      insertTextIndicators({
        classIndicators: ".slider-teachers-indicators",
        textClasses: ["text-primary-0", "fs-4", "fw-bold", "mb-0"],
        text: "Know our teachers",
      });
    });
    const { teachersAllAvailable, userAuthenticated, studentInfo } = useStudentInformation();
    return {
      teachersAllAvailable,
      userAuthenticated,
      studentInfo
    };
  },
};
</script>

<style >
.slider-teachers {
  grid-template-columns: 1fr 1fr;
}

.slider-teachers-card {
  grid-column: 1 / span 2;
}

.slider-teachers-indicators {
  margin-left: auto;
}

.grid-full-width{
  grid-column: 1/5 !important;
}
</style>