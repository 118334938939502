<template>
  <div class="bg-primary-5 border-r-10 p-4 dashboard-admin-teachers">
    <div class="d-flex justify-content-between align-items-center">
      <skeleton
        v-if="teachers.isLoading"
        borderRadius="10px"
        height="2rem"
        width="14rem"
        class="skeleton-dark"
      />
      <h1 class="text-primary-0 fw-bold fs-4" v-else>Teachers</h1>

      <skeleton
        v-if="teachers.isLoading"
        borderRadius="10px"
        height="2rem"
        width="10rem"
        class="skeleton-dark"
      />

      <div
        class="
          bg-white
          py-2
          px-3
          d-flex
          justify-content-center
          gap-2
          rounded-pill
          w-content
          align-items-center
        "
        v-else
      >
        <div class="status" :class="statusUsers['online'].class"></div>
        <span class="text-neutro-1">
          Online
          <span class="fw-bold text-primary-1 ms-2">
            {{ totalTeachersOnnline  }}
          </span>
        </span>
      </div>
    </div>
    <div class="grid-teachers mt-3" v-if="!teachers.isLoading">
      <div
        v-for="(card, index) in cardTeachers"
        :key="index"
        class="
          border-primary-1 border-r-10
          bg-white
          p-2
          d-flex
          align-items-center
          justify-content-center
          gap-4
          px-3
        "
      >
        <p class="fs-6 lh-sm">
          {{ card.label }}
        </p>
        <p class="text-secondary-1 fs-3 fw-bold">
          {{ teachers.data[card.value] || 0 }}
        </p>
      </div>
    </div>

    <div class="grid-teachers mt-3" v-if="teachers.isLoading">
      <skeleton
        v-for="(_, index) in [...Array(cardTeachers.length)].map((_, i) => i)"
        :key="index"
        borderRadius="10px"
        height="4.5rem"
        width="10rem"
        class="skeleton-dark"
      />
    </div>
  </div>
</template>

<script>
import useDashboard from "@/modules/admin/composables/Dashboard/useDashboard";
import statusUsers from "@/shared/data/statusUsers";

export default {
  setup() {
    const { cardTeachers, teachers , totalTeachersOnnline } = useDashboard();
    return { cardTeachers, teachers, statusUsers , totalTeachersOnnline };
  },
};
</script>