<template>
  <skeleton
    v-if="classesWeek.isLoading"
    borderRadius="10px"
    height="20rem"
    class="skeleton-dark"
  />

  <div v-else class="progress-list px-5 p-sm-1 pe-md-2 scroll pb-md-3 scroll-secondary">
    <div
      v-for="(classe, index) in getInfoClassesWeek"
      :key="index"
      class="progress-bar-grid progress-square mb-2 mt-2"
    >
      <p class="fw-bold">{{ classe.day }}</p>
      <progress-bar
        :value="(classe.count * 100) / 20"
        :showValue="false"
        class="w-100"
        :class="index === day - 1 ? 'progress-c1' : 'progress-a1'"
      />
      <p class="text-primary-1 text-end">{{ classe.count }}</p>
    </div>
  </div>
</template>

<script>
import useDashboardStudent from "@/modules/students/composables/Dashboard/useDashboard";
import useDashboardTeacher from "@/modules/teachers/composables/Dashboard/useDashboardMain";

import useSharedUI from "@/shared/composables/useSharedUI";

export default {
  setup() {
    const { userAuthenticated } = useSharedUI();

    const { classesWeek, getInfoClassesWeek, day } =
      userAuthenticated.value.role === "student"
        ? useDashboardStudent()
        : useDashboardTeacher();

    return {
      classesWeek,
      getInfoClassesWeek,
      day,
    };
  },
};
</script>

<style>
</style>