import { computed, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import useValidateFields from "@/shared/composables/useValidateFields";

import isValidateYTUrl from "@/shared/utils/validations/isValidateYTUrl";
import getYtUrlId from "@/shared/utils/getters/getYtUrlId";
import selectedImage from "@/shared/helpers/selectedImageHelper";
import { v4 as uuidv4 } from "uuid";
import { ref as refDb, set } from "firebase/database";
import database from "@/firebase/config";
const useAdminAddOrUpdate = () => {
  const store = useStore();
  const adminModal = computed(
    () => store.getters["admins/memberAdminModal"].addOrUpdate
  );
  let currentAdmin = computed(() => store.getters["admins/adminInfo"]);
  const changingPhoto = ref(false);
  const fields = ref({
    name: "",
    last_name: "",
    address: "",
    email: "",
    birth: "",
    hire_date: "",
    link_video: "",
    gender: "",
    phone_code_id: "",
    cellphone: null,
    photo: "",
    timezone: "",
    timezone_change: "",
    role_id: "",
  });

  const listings = computed(() => store.getters["shared/getListings"]);
  const isShowIframe = ref(false);
  const idIframe = ref("");
  const {
    validateFieldsRequireds,
    resetFieldsValues,
    formatDateFields,
    changeNumberToString,
  } = useValidateFields();

  watchEffect(() => {
    if (fields.value.link_video) {
      const { status, id } = getYtUrlId(fields.value.link_video);
      if (status === "success") {
        isShowIframe.value = isValidateYTUrl(
          "https://www.youtube.com/embed/" + id
        );
        idIframe.value = id;
      }
    } else {
      isShowIframe.value = false;
      idIframe.value = "";
    }
  });

  const inputFile = ref();
  const urlAvatar = ref("");

  const toggleAdminModal = ({ isOpen, status }) => {
    const values = { isOpen, status };
    if (!status) {
      values["editToAdmin"] = {};
      urlAvatar.value = "";
      isShowIframe.value = false;
    }
    store.commit("admins/setAdminModal", values);
    resetFieldsValues({ currentFields: fields.value });
  };

  const changeAvatar = () => {
    inputFile.value.click();
  };

  const onSelectedImage = async ({ target }) => {

    changingPhoto.value = true;
    const { success, file } = selectedImage({ target, image: urlAvatar });
    setTimeout(() => {
      if (success) fields.value.photo = file;
      changingPhoto.value = false;
    },1000)
  };

  const isLoadingAdminCreation = ref(false);
  const isLoadingAdminUpdate = ref(false);

  const idAdmin = ref(null);

  const createAdminFirebase = () => {
    const idAdmin = uuidv4();
    set(refDb(database, "Admins/" + idAdmin), {
      status: "offline",
      last_status: "offline",
    });
    return idAdmin;
  };

  const createOrUpdateAdmin = async (currentAction) => {
    let { newFields, haveFieldsToBeFilled, success } = validateFieldsRequireds({
      currentFields: fields.value,
      fieldRequireds: ["name", "last_name", "email", "timezone"],
    });

    const timezoneChange = listings.value.timesZones.data.find(item => item.timezone_change === fields.value.timezone_change)
    fields.value.timezone_change = timezoneChange.timezone_change
    fields.value.timezone = timezoneChange.timezone

    if (!haveFieldsToBeFilled && success) {
      newFields = formatDateFields({
        currentFields: newFields,
        fieldsToChange: ["birth", "hire_date"],
      });
      newFields = changeNumberToString({ currentFields: newFields });
      let success = false;

      if (currentAction === "create") {
        const idAdmin = createAdminFirebase();
        newFields.token_firebase = idAdmin;
        isLoadingAdminCreation.value = true;
        const res = await store.dispatch("admins/createAdmin", newFields);
        isLoadingAdminCreation.value = false;
        success = res.success;
      } else if (currentAction === "update") {
        if (
          typeof newFields.photo === "string" &&
          newFields.photo.startsWith("http")
        )
          delete newFields.photo;
        isLoadingAdminUpdate.value = true;
        newFields._method = "PUT";

        const res = await store.dispatch("admins/updateAdmin", {
          data: newFields,
          id: idAdmin.value,
        });
        isLoadingAdminUpdate.value = false;
        success = res.success;
      }

      if (!success) return;

      setTimeout(async () => {
        store.commit("admins/setAdminsBasicInfo", { isLoading: true });
        store.commit("admins/setAdminsAccount", { isLoading: true });
        if (newFields.email === currentAdmin.value.email) {
          await store.dispatch("admins/dashboardStart");
        }
        toggleAdminModal({ isOpen: false, status: "" });
        await store.dispatch("admins/getAdmins");
      }, 1500);
    }
  };

  watchEffect(() => {
    const editToAdmin =
      store.getters["admins/memberAdminModal"].addOrUpdate.editToAdmin;
    const hasValues = Object.values(editToAdmin)?.filter((v) => v).length > 1;
    if (hasValues) {
      idAdmin.value = editToAdmin.id;

      const keysFields = Object.keys(fields.value);
      const valueChanges = ["birth", "hire_date", "role", "cellphone"];

      for (let [key, value] of Object.entries(editToAdmin)) {
        if (key === "cellphone" && value) {
          fields.value[key] = Number(value);
        } else if ((key === "birth" || key === "hire_date") && value) {
          const new_date = new Date(value);
          new_date.setDate(new_date.getDate() + 1)
          fields.value[key] = new_date
        } else if (key === "photo" && value) {
          urlAvatar.value = value;
          fields.value[key] = value;
        } else if (key === "role" && value) {
          const role_id = listings.value.roles.filter(
            (r) => r.value === value
          )[0]?.id;
          if (role_id) fields.value["role_id"] = role_id;
        } else if (
          keysFields.includes(key) &&
          value &&
          !valueChanges.includes(key)
        ) {
          fields.value[key] = value;
        }
      }
    }
  });

  const getInfoAdmin = async ({ id, status }) => {
    store.commit("admins/setAdminModal", { isOpen: true, status });
    await store.dispatch("admins/getAdmin", id);
  };

  const selectCode = (e) => {
    const code = (e.value.includes("+") ? "" : "+") + e.value;
    const { countrycodes } = store.getters["shared/getListings"];
    const country = countrycodes.data.find((c) => c.dial_code === code);
    if(country) fields.value.phone_code_id = country.id ;
  } 

  return {
    adminModal,
    selectCode,
    toggleAdminModal,
    listings,
    changingPhoto,
    fields,
    isShowIframe,
    idIframe,
    inputFile,
    urlAvatar,
    changeAvatar,
    onSelectedImage,
    isLoadingAdminCreation,
    isLoadingAdminUpdate,
    getInfoAdmin,
    createOrUpdateAdmin,
  };
};

export default useAdminAddOrUpdate;
