<template>
  <div class="dashboard-admin">
    <banner-component />
    <calendar-component />

    <total-scheduled-component />
    <new-users-component />
    <students-component />

    <manager-path-component />
    <teachers-component />

    <download-charts-component />
  </div>
</template>

<script>
import useDashboard from "@/modules/admin/composables/Dashboard/useDashboard";
import BannerComponent from "@/modules/admin/components/Dashboard/BannerComponent";
import CalendarComponent from "@/modules/admin/components/Dashboard/CalendarComponent";
import StudentsComponent from "@/modules/admin/components/Dashboard/StudentsComponent";
import TeachersComponent from "@/modules/admin/components/Dashboard/TeachersComponent";
import DownloadChartsComponent from "@/modules/admin/components/Dashboard/DownloadChartsComponent";
import NewUsersComponent from "@/modules/admin/components/Dashboard/NewUsersComponent";
import TotalScheduledComponent from "@/modules/admin/components/Dashboard/TotalScheduledComponent";
import ManagerPathComponent from "@/modules/admin/components/Dashboard/ManagerPathComponent";

export default {
  components: {
    BannerComponent,
    CalendarComponent,
    StudentsComponent,
    DownloadChartsComponent,
    NewUsersComponent,
    TeachersComponent,
    TotalScheduledComponent,
    ManagerPathComponent,
  },
  setup() {
    return {
      ...useDashboard(),
    };
  },
};
</script>

<style lang="scss">
.dashboard-admin {
  display: grid;
  grid-template-columns: 1fr 16rem;
  grid-template-rows: 8rem 1fr 12rem 5rem 4rem;
  gap: 1rem;
  &-total-scheduled {
    // background-color: red;
    grid-row: 2 / 4;
  }
  &-manager-path {
    grid-column: 2 / 3;
    grid-row: 3 / 5;
  }
  &-students {
    grid-row: 4 / 6;
  }
  &-charts {
    grid-column: 2 / 3;
    grid-row: 5 / 7;
  }
  & .grid-students,
  & .grid-teachers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
}
</style>