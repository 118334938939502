<template>
  <div
    class="
      bg-white
      border-r-10
      p-4
      dashboard-admin-charts
      d-flex
      align-items-center
      flex-column
      justify-content-center
    "
  >
    <p class="text-primary-0 fw-bold fs-5 text-center">Download charts</p>
    <div class="mt-3 d-flex flex-column gap-2 w-100">
      <button
        @click="() => toPdfClasses()"
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          w-100
        "
      >
        <i class="pi pi-book"></i>
        Total classes
      </button>
      <!--       <button
        class="btn text-secondary-1 border-secondary-1 bg-hover-light-secondary-1 w-100 d-flex alig-items-center justify-content-center gap-1"
      >
        <span class="material-icons">school</span>
        Historical Users
      </button> -->
      <router-link
        v-if="userAuthenticated.role === 'admin'"
        :to="{ name: 'admin-analytics-financial' }"
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          w-100
          d-flex
          alig-items-center
          justify-content-center
          gap-1
        "
      >
        <span class="material-icons">insights</span>
        Financial
      </router-link>
      <router-link
        v-if="userAuthenticated.role === 'admin'"

        :to="{ name: 'admin-analytics-marketing', params: { view: 'Rewards' } }"
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          w-100
        "
      >
        <i class="pi pi-star-fill"></i>
        Rewards
      </router-link>
      <router-link
        v-if="userAuthenticated.role === 'admin'"

        :to="{
          name: 'admin-analytics-marketing',
          params: { view: 'Registrations' },
        }"
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          w-100
          d-flex
          alig-items-center
          justify-content-center
          gap-1
        "
      >
        <span class="material-icons"> account_box </span>
        Registrations
      </router-link>
    </div>
  </div>
</template>


<script>
import useDashboard from "@/modules/admin/composables/Dashboard/useDashboard";
import useSharedUI from "../../../../shared/composables/useSharedUI";
export default {
  setup() {
    const { toPdfClasses } = useDashboard();
    const { userAuthenticated } = useSharedUI()
    return {
      toPdfClasses,
      userAuthenticated
    };
  },
};
</script>