<template>
  <div class="bg-primary-5 border-r-10 p-4 dashboard-admin-total-scheduled">
    <div class="d-flex justify-content-between mb-3">
      <h1 class="text-primary-0 fw-bold fs-4">Total scheduled classes</h1>
      <select-button
        class="select-buttons-custom w-100"
        v-model="currentChart"
        :options="selectOptions"
      />
    </div>
    <Chart
      type="bar"
      :data="currentChartData[currentChart]"
      :options="chartOptions"
      :plugins="[ChartJsPluginDataLabels, bgColor]"
    />
  </div>
</template>

<script>
import useDashboard from "@/modules/admin/composables/Dashboard/useDashboard";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import bgColor from "@/shared/utils/charts/bgColor";
export default {
  setup() {
    const { selectOptions, currentChart, currentChartData, chartOptions } =
      useDashboard();
    return {
      selectOptions,
      currentChart,
      bgColor,
      currentChartData,
      chartOptions,
      ChartJsPluginDataLabels,
    };
  },
};
</script>
