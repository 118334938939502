import { useStore } from "vuex";
import moment from "moment";
import isValidateYTUrl from "@/shared/utils/validations/isValidateYTUrl";

const useValidateFields = () => {
  const store = useStore();

  const defaultMsgToast = {
    isOpen: true,
    status: "error",
    message: "Please fill in the required fields",
  };
  const showToast = (msgToast = {}) => {
    store.commit("shared/uiNotificationStatus", {
      ...defaultMsgToast,
      ...msgToast,
    });
    store.dispatch("shared/resetNotificationStatus");
  };

  const resetFieldsValues = ({ currentFields }) => {
    for (let [key, _] of Object.entries(currentFields)) {
      const fieldsToNull = ["cellphone"];
      if (fieldsToNull.includes(key)) currentFields[key] = null;
      else currentFields[key] = "";
    }
  };

  const defaultCustomToastMsg = {};

  const validateValues = (values) => {
    return Object.values(values).filter((val) => val).length > 0;
  };

  const validateFieldsRequireds = ({
    currentFields,
    fieldRequireds,
    customToastMsg = defaultCustomToastMsg,
    deleteFields = true,
  }) => {
    let haveFieldsToBeFilled = false;
    let success = true;

    const validateToast = (fieldName = "") => {
      haveFieldsToBeFilled = true;
      const hasCustomToastMsg = validateValues(customToastMsg);

      if (hasCustomToastMsg && typeof customToastMsg[fieldName] === "string") {
        showToast({ message: customToastMsg[fieldName] });
        return;
      }
      showToast();
    };
    for (let [key, value] of Object.entries(currentFields)) {
      if (!value && fieldRequireds.includes(key)) {
        validateToast(key);
        break;
      } else if (
        Array.isArray(value) &&
        value.length === 0 &&
        fieldRequireds.includes(key)
      ) {
        validateToast(key);
        break;
      } else if (
        key === "cellphone" &&
        value &&
        !currentFields["phone_code_id"]
      ) {
        showToast({ message: "Please, entered a phone code" });
        success = false;
        break;
      } else if (
        key === "phone_code_id" &&
        value &&
        !currentFields["cellphone"]
      ) {
        success = false;
        showToast({ message: "Please, entered a phone" });
        break;
      } else if (key === "link_video" && value && !isValidateYTUrl(value)) {
        showToast({ message: "Please, entered a link valid" });
        success = false;
        break;
      } else if (!value && !fieldRequireds.includes(key) && deleteFields) {
        delete currentFields[key];
      } else if (
        Array.isArray(value) &&
        value.length === 0 &&
        !fieldRequireds.includes(key) &&
        deleteFields
      ) {
        delete currentFields[key];
      }

      // if(deleteFields)
    }
    return { haveFieldsToBeFilled, newFields: currentFields, success };
  };

  const formatDateFields = ({
    currentFields,
    fieldsToChange = [],
    format = "YYYY-MM-DD",
  }) => {
    let fields = currentFields;
    if (typeof fields === "object" && !Array.isArray(fields)) {
      const hasValues = validateValues(fields);
      if (hasValues && fieldsToChange.length > 0) {
        fields = { ...currentFields };
        for (let [key, value] of Object.entries(fields)) {
          if (
            fieldsToChange.includes(key) &&
            value &&
            typeof value !== "string"
          ) {
            fields[key] = moment(value).format(format);
          }
        }
      }
    } else if (typeof fields === "string") {
      fields = moment(fields).format(format);
    }

    return fields;
  };

  const changeNumberToString = ({ currentFields }) => {
    let fields = currentFields;
    if (typeof fields === "object" && !Array.isArray(fields)) {
      const hasValues = validateValues(fields);
      if (hasValues) {
        for (let [key, value] of Object.entries(fields)) {
          if (typeof value === "number") fields[key] = value + "";
        }
      }
    } else if (typeof fields === "number") {
      fields = fields + "";
    }

    return fields;
  };

  return {
    showToast,
    resetFieldsValues,
    validateFieldsRequireds,
    formatDateFields,
    changeNumberToString,
  };
};

export default useValidateFields;
