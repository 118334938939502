<template>
  <div class="bg-white border-r-10 p-4">
    <p class="text-primary-0 fw-bold fs-5 text-center">Add new users</p>
    <div class="mt-3 d-flex flex-column gap-2">
      <button
        v-if="userAuthenticated.role === 'admin'"
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          w-100
          d-flex
          alig-items-center
          justify-content-center
          gap-1
        "
        @click="toggleLeadAddModal(true)"
      >
        <span class="material-icons"> school </span>
        New lead
      </button>
      <button
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          w-100
          d-flex
          alig-items-center
          justify-content-center
          gap-1
        "
        @click="toggleTeacherModal({ isOpen: true, status: 'add' })"
      >
        <span class="material-icons"> people_outline </span>
        New teacher
      </button>
      <button
       v-if="userAuthenticated.role === 'admin'"
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          w-100
          d-flex
          alig-items-center
          justify-content-center
          gap-1
        "
        @click="toggleAdminModal({ isOpen: true, status: 'add' })"
      >
        <span class="material-icons"> supervised_user_circle </span>
        New admin
      </button>
    </div>
  </div>
</template>

<script>
import useTeacherAddOrUpdate from "@/modules/admin/composables/Members/Teachers/useTeacherAddOrUpdate";
import useLeadAdd from "@/modules/admin/composables/Members/Students/useLeadAdd";
import useAdminAddOrUpdate from "@/modules/admin/composables/Members/Admins/useAdminAddOrUpdate";
import useSharedUI from "../../../../shared/composables/useSharedUI";
export default {
  setup() {
    const { toggleTeacherModal } = useTeacherAddOrUpdate();
    const { toggleLeadAddModal } = useLeadAdd();
    const { toggleAdminModal } = useAdminAddOrUpdate();
    const { userAuthenticated } = useSharedUI()
    return {
      toggleTeacherModal,
      userAuthenticated,
      toggleLeadAddModal,
      toggleAdminModal,
    };
  },
};
</script>