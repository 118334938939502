<template>
  <skeleton
    v-if="dashboard.isLoading"
    borderRadius="10px"
    height="10rem"
    class="skeleton-dark"
  />
  <div
    v-else
    class="
      dashboard-grid-banner-welcome
      bg-primary-5
      border-r-10
      p-3
      d-flex
      flex-column
      justify-content-center
    "
  >
    <h1 class="text-primary-0 fw-bold fs-3">
      Hello, {{ dashboard.data.student_name }}!
    </h1>
    <p class="text-neutro-1">Welcome back! We wish you a bright day!</p>
  </div>
</template>


<script>
import useDashboard from "@/modules/students/composables/Dashboard/useDashboard";
export default {
  setup() {
    const { dashboard } = useDashboard();
    return {
      dashboard,
    };
  },
};
</script>
