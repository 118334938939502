<template>
  <div class="dashboard-grid">
    <div class="bg-primary-5 border-r-10 p-4 dashboard-grid-welcome">
      <skeleton
        v-if="!teacherInfo"
        borderRadius="10px"
        height="100%"
        class="skeleton-dark"
      />
      <template v-else>
        <h3 class="text-primary-0 fw-bold">
          Hello, {{ isEmpty(teacherInfo.name) }}
        </h3>
        <p class="py-2">Welcome back! We wish you a bright day!</p>
      </template>
    </div>
    <div class="dashboard-grid-progress">
      <my-progress-component />
    </div>
    <scheduled-classes-component />
    <last-student-and-scheduleds-class-component />
  </div>
</template>

<script>
import CardScheduledClassComponent from "@/shared/components/Cards/CardScheduledClassComponent";
import CalendarDatesComponent from "@/shared/components/Calendar/CalendarDatesComponent";
import MyProgressComponent from "@/shared/components/Levels/MyProgressComponent";
import ScheduledClassesComponent from "@/shared/components/Dashboard/ScheduledClassesComponent";
import isEmpty from "@/shared/utils/validations/isEmpty";

import LastStudentAndScheduledsClassComponent from "@/modules/teachers/components/Dashboard/LastStudentAndScheduledsClassComponent";
import useDashboardMain from "@/modules/teachers/composables/Dashboard/useDashboardMain";
import useTeacherInformation from "@/modules/teachers/composables/useCurrentTeacher";

export default {
  name: "DashboardView",
  components: {
    CardScheduledClassComponent,
    CalendarDatesComponent,
    MyProgressComponent,
    ScheduledClassesComponent,
    LastStudentAndScheduledsClassComponent,
  },
  setup() {
    const { teacherInfo } = useTeacherInformation();
    const { loadServices } = useDashboardMain();

    loadServices();
    return {
      teacherInfo,
      isEmpty,
    };
  },
};
</script>

<style lang="scss" scoped>
.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr 16rem;
  grid-template-rows: 8rem;
  gap: 1rem;
  overflow: hidden;

  &-welcome {
    grid-column: 1 / 2;
    @media screen and (max-width: 768px){
      grid-column: 1 / 5;
    }
  }
  &-calendar {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
  &-progress {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
}
</style>
