<template>
  <div
    class="calendar-dates scroll scroll-secondary"
    :class="'calendar-' + size"
  >
    <div class="calendar-date mb-2" v-for="(date,index) in dates" :key="index">
      <span>{{role === "student" ? date.lesson_start.substr(10,6) :  date.lesson_start.substr(10,6)}}</span>
      <span>{{role === "student" ? `Prof. ${date.teacher_name}`:`Student's ${date.student_name} ${date.student_last_name}`}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendarDatesComponent",
  props: {
    dates: {
      type: Array,
      default: [1, 2, 3, 4, 5, 6, 7],
    },
    size: {
      type: String,
      default: "big",
    },
    role:{
      type:String,
      default:"student"
    }
  },
};
</script>