<template>
  <div
    class="
      p-2 p-md-5
      text-center text-neutro-2
      d-flex
      flex-column
      justify-content-center
    "
    :class="{
      'h-100': userAuthenticated.role === 'teacher',
    }"
  >
    <!-- <p class="mb-2">You haven't scheduled any classes yet.</p>
    <p>If you do, you will see your classes here.</p> -->
    <p>No classes have been scheduled for you.</p>
  </div>
</template>

<script>
import useSharedUI from "@/shared/composables/useSharedUI";
export default {
  setup() {
    const { userAuthenticated } = useSharedUI();
    return {
      userAuthenticated,
    };
  },
};
</script>