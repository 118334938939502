import { computed, ref, watchEffect  } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'
import moment from "moment";
const useDashboard = () => {
  const store = useStore();
  const infoLevels = ref({});
  const router = useRouter()
  const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  const day = new Date(store.getters['shared/currentTimeTimezone']).getDay() + 1;

  const loadServices = async () => {
    const userAuthenticated = store.getters["shared/getUserAuthentication"]
    await store.dispatch("students/dashboardStart");
    store.commit("students/setTeachersAll", { filter: false });
    const actualDate = moment.utc(new Date(store.getters['shared/currentTimeTimezone']))

    // if(actualDate.minutes() < 30){
    //   let difference = 30 - actualDate.minutes();
    //   if(difference < 15){
    //     difference = 60 -  (30 - difference)
    //     await store.dispatch("students/getTeachersAllAvailable" , { payload : { dates : [ { date: actualDate.add(difference,'minutes').format('YYYY-MM-DD HH:mm') + ":00" }] } } );

    //   }else{
    //     await store.dispatch("students/getTeachersAllAvailable" , { payload : { dates : [ { date: actualDate.add(difference,'minutes').format('YYYY-MM-DD HH:mm') + ":00"  }] } } );
    //   }
    // }else{
    //   let difference = 60 - actualDate.minutes()
    //   if(difference < 15){
    //     difference = 60 -  (30 - difference)
    //     console.log(difference)
    //     await store.dispatch("students/getTeachersAllAvailable" , { payload : { dates : [ { date: actualDate.add(difference,'minutes').format('YYYY-MM-DD HH:mm') + ":00" }] }} );
    //   }else{
    //     await store.dispatch("students/getTeachersAllAvailable" , { payload : { dates : [ { date: actualDate.add(difference,'minutes').format('YYYY-MM-DD HH:mm') + ":00" }] }} );
    //   }
    // }
    if(userAuthenticated.completed_info){
      await store.dispatch("students/getTeachersAllAvailable" , { payload : { dates : [] }} );
      await store.dispatch("students/classesTakenThisWeek");
      await store.dispatch("students/classesThisWeekPending");
    }else{
      store.commit("students/classesTakenThisWeek",{isLoading: false});
    }
  };

  const getInfoClassesWeek = computed(() => {
    const dashboard = store.getters["students/dashboardStart"];
    let classesWeek = dashboard.data.student_my_lessons_day;
    let newClasesWeek = new Array(7);
    if (classesWeek !== undefined) {
      classesWeek.forEach((classe) => {
        let newClass = classe;
        newClass.day = days[newClass.day_of_week - 1];
        newClasesWeek[newClass.day_of_week - 1] = newClass;
      });
      for (let i = 0; i <= 6; i++) {
        if (newClasesWeek[i] === undefined || !newClasesWeek[i]) {
          newClasesWeek[i] = { count: 0, day_of_week: i, day: days[i] };
        }
      }
      return newClasesWeek;
    }
    return [];
  });

  const getInfo = () => {
    let data = dashboard.value.data;
    let levels_start = {};
    let levels = {};
    let levels_finish = {};
    let i = 0;
    data.subtitles?.forEach((title) => {
      let object = { ["" + i]: title };
      levels_start[title.level_id - 1] = {
        ...levels_start[title.level_id - 1],
        ...object,
      };
      i++;
    });
    Object.entries(levels_start).forEach(([key, value]) => {
      let level_info = {
        do_it: 0,
        total: 0,
        porcentage: 0,
        level_name_short: "",
        level_name: "",
      };
      Object.entries(value).forEach(([key2, value2]) => {
        if (value2.total_level_do === 1) {
          level_info.do_it = level_info.do_it + 1;
        }
        level_info.level_name_short = value2.level_name_short.toLowerCase();
        level_info.level_name = value2.level_name;
        level_info.total++;
      });
      level_info.porcentage = (level_info.do_it * 100) / level_info.total;
      levels[key] = level_info;
    });

    

    let initial_level = null;
    Object.entries(levels).forEach(([key, value]) => {
      if (initial_level === null) {
        initial_level = {
          ...value,
          initial_level_parent: value.level_name.substr(0, 7),
        };
        levels_finish[key] = initial_level;
      } else {
        if (
          initial_level.initial_level_parent !== value.level_name.substr(0, 7)
        ) {
          initial_level = {
            ...value,
            initial_level_parent: value.level_name.substr(0, 7),
          };
          levels_finish[key] = initial_level;
        }
      }
    });
    infoLevels.value = levels_finish;
  };

  const goToTeachers = () => {
    router.push({name:'student-my-teachers'});
  }

  const goToFeedbacks = () => {
    router.push({name:'student-teachers-feedback'});
  }

  const teachers = computed(() => {
    return store.getters["students/teachersAll"];
  });

  const dashboard = computed(() => {
    return store.getters["students/dashboardStart"];
  });

  const classesWeek = computed(() => {
    return store.getters["students/classesTakenThisWeek"];
  });

  const teachersFavorites = computed(() => {
    return store.getters["students/teachersFavorites"];
  });

  const classesThisWeekPending = computed(() => {
    return store.getters["students/classesThisWeekPending"];
  });



  return {
    teachers,
    teachersFavorites,
    dashboard,
    goToTeachers,
    loadServices,
    goToFeedbacks,
    getInfo,
    classesThisWeekPending,
    infoLevels,
    classesWeek,
    getInfoClassesWeek,
    day,
  };
};

export default useDashboard;
