const getYtUrlId = (url) => {
  if (!url) {
    return { status: "error" };
  }
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length == 11) {
    return {
      id: match[2],
      status: "success",
    };
  }
  return {
    status: "error",
  };
};

export default getYtUrlId;
