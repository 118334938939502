const statusUser = {
  online: {
    class: "bg-feedback-success",
    text: "Online",
  },
  online: {
    class: "bg-feedback-success",
    text: "Online",
  },
  absent: {
    class: "bg-feedback-warning",
    text: "Absent",
  },
  busy: {
    class: "bg-feedback-error",
    text: "Busy",
  },
  offline: {
    class: "bg-neutro-2",
    text: "Offline",
  },
};

export default statusUser;
