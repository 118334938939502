<template>
  <div class="p-2" :style="!isCompleted ? 'min-height:250px' : ''">
    <div class="d-flex gap-3 flex-column flex-sm-row align-items-center">
      <div class="header-user- text-center position-relative">
        <avatar :image="photo ?? '/assets/images/avatar.png'" shape="circle" />
        <div class="
            user-status
            w-auto
            bg-white
            py-1
            px-2
            shadow-sm
            d-flex
            gap-2
            align-items-center
          ">
          <div class="status"
            :class="true ? (teacher_status !== undefined ? bgStatus(teacher_status) : 'loading...') : ''"></div>
          <span class="fs-6">{{ teacher_status !== "" && teacher_status !== undefined ?
              teacher_status?.slice(0, 1).toUpperCase() + teacher_status?.slice(1) : "loading..."
          }}</span>
        </div>

      </div>
      <h4 class="mb-0 fs-5 text-primary-0 fw-bold text-center">
        {{ name }} {{ last_name }}
      </h4>
    </div>
    <div class="my-3 text-center">
      <p class="fs-6 lh-sm">
        {{ description }}
      </p>
    </div>
    <div class=" mt-3" :class="isNewStudent ? 'text-center text-md-end' : 'text-center'">
      <button @click="() => goToBook(true, { teacher_id, last_name, name, description, photo })" v-if="isCompleted" class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          p-2
          px-4
        ">
        Book a class
      </button>
    </div>
  </div>
</template>

<script>
import useSharedUI from "../../../../shared/composables/useSharedUI";
import usePrivateLayout from "@/shared/composables/Layouts/usePrivateLayout";
import bgStatus from "@/shared/utils/style/bgStatus";

export default {
  props: {
    name: {
      type: String,
    },
    teacher_id: {
      type: Number,
    },
    last_name: {
      type: String,
    },
    description: {
      type: String,
      default:
        "Start learning now!"
    },
    photo: {
      type: String,
    },
    teacher_status: {
      type: String,
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    isNewStudent: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { goToBook } = useSharedUI();
    const { status } = usePrivateLayout();

    return {
      bgStatus,
      status,
      goToBook,
    };
  },
};
</script>