<template>
  <div class="w-100 text-center">
    <h2 class="text-primary-0 fs-5 mb-1">
      {{ title }}
    </h2>
    <template v-if="userAuthenticated.role === 'student'">
      <h1 class="fs-1 fw-bold text-accent-1">
        {{ dashboard.data.student_level_short_id }}
      </h1>
      <div class="progress-levels-grid mt-2">
        <div v-for="(value, key) in infoLevels" :key="key">
          <progress-bar
            :value="value.porcentage"
            :showValue="false"
            class="w-100"
            :class="`progress-${value.level_name_short}`"
          />
          <p class="mt-1">{{ value.initial_level_parent }}</p>
        </div>
      </div>
    </template>
    <template v-if="userAuthenticated.role === 'teacher'">
      <h1 class="fs-1 fw-bold text-accent-1">
        {{ student.data.student_info.level_name_short }}
      </h1>

      <div class="progress-levels-grid mt-2">
        <div v-for="(value, key) in infoLevelsStudent" :key="key">
          <progress-bar
            :value="value.porcentage"
            :showValue="false"
            class="w-100"
            :class="`progress-${value?.level_name_short.toLowerCase()}`"
          />
          <p class="mt-1">{{ value.initial_level_parent }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import useDashboard from "@/modules/students/composables/Dashboard/useDashboard";
import useSharedUI from "@/shared/composables/useSharedUI";
import useInformationTeacher from "@/modules/teachers/composables/Student/useInformation";
export default {
  name: "ProgressLevelComponent",
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const { dashboard, getInfo, infoLevels } = useDashboard();
    const { userAuthenticated } = useSharedUI();
    const { student, getInfoStudent, infoLevelsStudent } = useInformationTeacher();
    getInfo();
    getInfoStudent();

    return {
      dashboard,
      student,
      infoLevelsStudent,
      infoLevels,
      userAuthenticated,
    };
  },
};
</script>

<style></style>
