<template>
  <div
    class="bg-white border-r-10 px-3 py-4 w-100 dashboard-grid-calendar"
    v-if="!showSection"
    :class="[
      userAuthenticated.role === 'student'
        ? 'grid-col-student'
        : 'grid-col-teacher',
      !userAuthenticated.completed_info ? ' bl-shadow' : '',
    ]"
  >
    <div class="col-calendar">
      <calendar
        class="
          calendar-custom calendar-custom-small
          title-big-blue-dark
          calendar-hidden-arrows
        "
        dateFormat="dd.mm.yy"
        :inline="true"
        :showWeek="false"
        :showOtherMonths="false"
        :showOnFocus="false"
      />
    </div>
    <div class="col-classes">
      <div
        class="d-flex flex-column flex-xxl-row justify-content-between gap-2 mb-4"
        v-if="userAuthenticated.role === 'student'"
      >
        <h2 class="text-primary-0 fs-5 m-0">Book a new class</h2>
        <div class="d-flex flex-row flex-column flex-md-row gap-2 w-100">
          <button
            @click="() => goToBook()"
            class="
              btn
              w-100
              text-secondary-1
              border-secondary-1
              bg-hover-light-secondary-1
              p-2
              px-3
              d-flex
              justify-content-center
              gap-1
            "
          >
            <span class="material-icons"> calendar_today </span>
            By time
          </button>
          <button
            @click="() => goToBook(true)"
            class="
              btn
              w-100
              text-secondary-1
              border-secondary-1
              bg-hover-light-secondary-1
              p-2
              px-3
              justify-content-center
              d-flex
              gap-1
            "
          >
            <span class="material-icons"> person </span>
            By teacher
          </button>
        </div>
      </div>

      <template v-if="userAuthenticated.role === 'student'">
        <skeleton
          v-if="classesForDayStudent.isLoading"
          borderRadius="10px"
          height="15rem"
          class="skeleton-dark"
        />
        <template v-else>
          <empty-scheduled-classes-component
            v-if="classesForDayStudent.data.length === 0"
          />
          <calendar-dates-component
            v-if="classesForDayStudent.data.length > 0"
            :size="'small'"
            :dates="classesForDayStudent.data"
            :role="userAuthenticated.role"
          />
        </template>
      </template>

      <template v-if="userAuthenticated.role === 'teacher'">
        <skeleton
          v-if="classesForDay.isLoading"
          borderRadius="10px"
          height="15rem"
          class="skeleton-dark"
        />
        <template v-else>
          <empty-scheduled-classes-component
            v-if="classesForDay.data.length === 0"
          />
          <calendar-dates-component
            v-if="classesForDay.data.length > 0"
            :size="'md'"
            :dates="classesForDay.data"
            :role="userAuthenticated.role"
          />
        </template>
      </template>
    </div>
  </div>
  <div
    class="bg-setup-profile vh-100 w-100 position-absolute"
    v-if="
      showSection &&
      userAuthenticated.role === 'student' &&
      !userAuthenticated.completed_info
    "
  ></div>
  <div
    id="go_to_profile"
    class="
      bg-primary-5
      border-r-10
      position-relative
      p-4
      dashboard-grid-calendar
      col-1
      w-100
    "
    ref="item"
    :style="[!userAuthenticated.completed_info ? 'z-index: 1' : '']"
    :class="[
      userAuthenticated.role === 'student'
        ? 'grid-col-student'
        : 'grid-col-teacher',
    ]"
    v-if="showSection && userAuthenticated.role === 'student'"
  >
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="text-primary-0 fs-3 fw-bold">Getting started</h1>
      <i
        v-if="userAuthenticated.completed_info"
        class="pi pi-times fs-4 text-primary-1 pointer"
        @click="removeSection"
      ></i>
    </div>
    <p class="fw-bold mb-3">
      Great to have you on board! Please set up your profile to start your Spanish journey!
    </p>
    <div class="gap-3" :class="userAuthenticated.completed_info ? (widthScreen < 768 ? 'mini-tour-cards' : 'mini-tour-cards-authenticated') : 'mini-tour-cards'">
      <div
        class="
          position-relative
          p-4
          border-r-10
          bl-shadow
          text-center
          mini-tour-card
        "
        :class="[
          !userAuthenticated.completed_info
            ? 'bg-accent-1 text-white pointer'
            : 'bg-white text-primary-0',
        ]"
        @click="
          () => {
            if (!userAuthenticated.completed_info)
              router.push({ name: 'student-profile' });
          }
        "
      >
        <div
          class="top-0 start-0 mt-2 px-3 ms-2 position-absolute"
          :class="
            !userAuthenticated.completed_info
              ? 'text-white bg-secondary-1'
              : 'text-secondary-1 bg-white'
          "
          style="border-radius: 15px"
        >
          <span>{{
            userAuthenticated.completed_info ? "Done" : " First step"
          }}</span>
        </div>
        <img
          v-if="!userAuthenticated.completed_info"
          src="/assets/images/icon-account.png"
          class="mt-3"
        />
        <img
          v-if="userAuthenticated.completed_info"
          src="/assets/images/icon-account-done.png"
        />

        <h3 class="fs-4 fw-bold mt-2">Set up your profile</h3>
        <p>Fill in your personal information to boost your experience.</p>
      </div>
      <div
        v-if="userAuthenticated.completed_info"
        class="
          position-relative
          p-4
          border-r-10
          bl-shadow
          text-center
          mini-tour-card
          pointer
        "
        :class="[
          !userAuthenticated.completed_info
            ? 'bg-white text-primary-0'
            : 'bg-primary-0 text-white',
          userAuthenticated.completed_info ? 'pointer' : '',
        ]"
        @click="
          () => {
            if (userAuthenticated.completed_info) {
              store.commit('students/startTour', { isOpen: true });
            }
          }
        "
      >
        <span
          class="
            position-absolute
            top-0
            start-0
            mt-2
            px-3
            ms-2
            bg-primary-1
            text-white
            option-step
            d-none
          "
          >Optional step</span
        >
        <img
          v-if="!userAuthenticated.completed_info"
          src="/assets/images/icon-search.png"
          class="mt-3"
        />
        <img
          v-if="userAuthenticated.completed_info"
          src="/assets/images/icon-search-done.png"
        />

        <h3 class="fs-4 fw-bold mt-2">Take the mini tour</h3>
        <p>Get a quick tour to the different sections of the web.</p>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyScheduledClassesComponent from "./EmptyScheduledClassesComponent";
import CalendarDatesComponent from "@/shared/components/Calendar/CalendarDatesComponent";
import styleCurrentWeekCalendar from "@/shared/utils/style/styleCurrentWeekCalendar";
import useSharedUI from "@/shared/composables/useSharedUI";
import useStudentInformation from "@/modules/students/composables/useStudentInformation";
import { onMounted, onUpdated, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useTeacherInformation from "@/modules/teachers/composables/Dashboard/useDashboardMain";
export default {
  components: {
    EmptyScheduledClassesComponent,
    CalendarDatesComponent,
  },
  setup() {
    const router = useRouter();
    
    const { userAuthenticated, goToBook } = useSharedUI();
    const { classesForDayStudent } = useStudentInformation();
    const { classesForDay } = useTeacherInformation();
    const showSection = ref(true);
    const item = ref(null);
    const store = useStore();
    const removeSection = () => {
      showSection.value = false;
    };
    
    const widthScreen = ref(window.innerWidth);

    onMounted(() => {
      styleCurrentWeekCalendar();
      const container = document.getElementById('body-main')
      if(showSection.value &&
      userAuthenticated.value.role === 'student' &&
      !userAuthenticated.value.completed_info){   
        if(container){
          container.addEventListener('scroll',() =>{
            if(item.value){
              console.log(item.value.offsetTop)
              container.scrollTo(0,item.value.offsetTop - 200)
            }
            
          })
        }
      }else{
        if(container){
          container.removeEventListener('scroll',() =>{
            container.scrollTo(0,0)
          })
        }
      }
    });
    
    watchEffect(() => {
      const { completed_info, firstProfile } =
        store.getters["shared/getUserAuthentication"];
      if (completed_info && firstProfile) {
        showSection.value = false;
      }
      const container = document.getElementById('body-main')
      if(showSection.value &&
      userAuthenticated.value.role === 'student' &&
      !userAuthenticated.value.completed_info){   
        if(container){
          container.addEventListener('scroll',() =>{
            if(item.value){
              console.log(item.value.offsetTop)
              container.scrollTo(0,item.value.offsetTop - 200)
            }
            
          })
        }
      }else{
        if(container){
          container.removeEventListener('scroll',() =>{
            container.scrollTo(0,0)
          })
        }
      }
    });


    return {
      userAuthenticated,
      goToBook,
      classesForDayStudent,
      classesForDay,
      removeSection,
      showSection,
      router,
      widthScreen,
      store,
      item
    };
  },
};
</script>

<style lang="scss" scoped>
.dashboard-grid-calendar {
  display: grid;
  grid-template-columns: 16rem 1fr;
  gap: 1.5rem;
  // grid-column: 1 / 4;
  &.grid-col-student {
    grid-column: 1 / 4;
  }
  &.grid-col-teacher {
    grid-column: 1 / 2;
    @media screen and (max-width: 768px){
      grid-column: 1 / 5;
    }
  }
  &.col-1 {
    display: flex;
    flex-direction: column;
    gap: 0px;
    // grid-template-columns: 1fr;
  }
}
.bg-setup-profile {
  top: 0;
  left: 0;
  background: black;
  opacity: 0.75;
  z-index: 1;
}

.mini-tour-cards-authenticated {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mini-tour-cards{
  display: grid;
  grid-template-columns: 1fr;
}
.mini-tour-card:hover {
  box-shadow: 4px 7px 10px rgb(0 0 0 / 29%), 2px 4px 0px rgb(0 0 0 / 17%),
    0px 1px 1px rgb(0 0 0 / 25%) !important;
  & .option-step {
    display: block !important;
  }
}
</style>
