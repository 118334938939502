<template>
  <div
    class="
      bg-white
      px-4
      py-2
      d-flex
      flex-column
      justify-content-center
      border-r-10
    "
  >
    <calendar
      class="
        dashbaord-calendar-week
        calendar-custom calendar-custom-small
        title-blue-dark
        calendar-hidden-arrows
      "
      dateFormat="dd.mm.yy"
      :inline="true"
      :showWeek="false"
      :showOtherMonths="false"
      :showOnFocus="false"
    />
    <hr class="my-1 w-100" />
  </div>
</template>

<script>
import { onBeforeMount, onMounted } from "vue";
import showWeek from "@/shared/utils/style/showWeek";

export default {
  setup() {
    onMounted(() => {
      showWeek("dashbaord-calendar-week");
      // console.log("calendar");
    });
  },
};
</script>