<template>
  <div class="dashboard-grid-favorites">
    <div class="bg-primary-5 p-2 border-r-10 p-3 last-teacher" v-if="dashboard.data.my_last_teacher" :class="!teachersFavorites.data.length && 'grid-2-col'">
      <skeleton
        v-if="dashboard.isLoading"
        height="100%"
        borderRadius="10px"
        class="skeleton-dark container-empty-favorites"
      />
      <template v-else>
        <h2 class="text-primary-0 fw-bold fs-4 mb-4">Your latest teacher</h2>
        <card-small-info-component
          :total="2"
          :name="dashboard.data.my_last_teacher?.name"
          :cardTypes="['view-profile']"
          :photo="dashboard.data.my_last_teacher?.photo?imgPath+dashboard.data.my_last_teacher?.photo : '/assets/images/avatar.png'"
          :goViewProfile="goToTeachers"

        />
        <button
          v-if="!dashboard.data.my_last_teacher?.send_my_feedback"
          class="
            btn
            text-white
            bg-secondary-1 bg-hover-secondary-1
            py-2
            px-3
            bl-shadow
            w-100
            mt-3
          "
          @click="goToFeedbacks"
        >
          Provide feedback
        </button>
      </template>
    </div>
    <div class="bg-white p-2 border-r-10 p-3 h-100 teachers" v-if="teachersFavorites.data.length"
    :class="!dashboard.data.my_last_teacher && 'grid-2-col'">
      <p class="text-primary-0 fw-bold fs-4 mb-3">Book with your favorites</p>

      <skeleton
        v-if="teachersFavorites.isLoading"
        height="81%"
        borderRadius="10px"
        class="skeleton-dark container-empty-favorites"
      />
      <div
        class="
          text-center text-neutro-2
          d-flex
          flex-column
          justify-content-between
          container-empty-favorites
          px-3
        "
        v-if="
          teachersFavorites.data.length === 0 && !teachersFavorites.isLoading
        "
      >
        <div class="p-5">
          <p class="mb-1">You haven't added any favorite teachers yet.</p>
          <p>Choose one to see them here.</p>
        </div>

        <button
          class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            p-2
            w-100
          "
          @click="goToTeachers"
        >
          Add your favorite teacher
        </button>
      </div>
      <template
        v-if="teachersFavorites.data.length > 0 && !teachersFavorites.isLoading"
      >
        <Carousel
          :value="teachersFavorites.data"
          :numVisible="1"
          
        >
          <template #item="slotProps">
            <card-teacher-component
              :name="slotProps.data.name"
              :last_name="slotProps.data.last_name"
              :isCompleted="true"
              :isNewStudent="studentInfo.is_new_student"
              :teacher_status="slotProps.data.status"
              :teacher_id="slotProps.data.teacher_id"
              :photo="slotProps.data.photo"
            />
          </template>
        </Carousel>
      </template>
    </div>
  </div>
</template>

<script>
import useStudentInformation from "@/modules/students/composables/useStudentInformation";
import useDashboard from "@/modules/students/composables/Dashboard/useDashboard";
import CardTeacherComponent from "./CardTeacherComponent";
import CardSmallInfoComponent from "@/shared/components/Cards/CardSmallInfoComponent";
import { onUpdated } from "vue";

export default {
  name: "LastTeacherAndFavoritesComponent",
  components: {
    CardTeacherComponent,
    CardSmallInfoComponent,
  },

  setup() {
    const { teachersFavorites , studentInfo } = useStudentInformation();
    const { dashboard , goToTeachers , goToFeedbacks } = useDashboard();
    const imgPath = process.env.VUE_APP_API_PROD + '/storage/'

    return { teachersFavorites, dashboard , goToTeachers  , goToFeedbacks , studentInfo, imgPath };
  },
};
</script>

<style lang="scss" scoped>
.dashboard-grid-favorites {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 0.5rem));
  gap: 1rem;
  & .last-teacher {
    width: 100%;
  }
  & .teachers {
    // max-width: 28rem;
    width: 100%;
  }
}
.container-empty-favorites {
  height: 81%;
}
</style>
