import { computed, ref } from "vue";
import { useStore } from "vuex";

const useLeadAdd = () => {
  const store = useStore();
  const fields = ref({
    name: "",
    last_name: "",
    email: "",
  });
  const leadAddModal = computed(
    () => store.getters["admins/memberStudentModal"].lead
  );

  const toggleLeadAddModal = (isOpen) => {
    store.commit("admins/memberStudentsLeadModal", { isOpen });
  };

  const cancel = () => {
    store.commit("admins/memberStudentsLeadModal", { isOpen: false });
  };

  const save = async () => {
    if (Object.values(fields.value).filter((field) => field).length === 0) {
      store.commit("shared/uiNotificationStatus", {
        status: "error",
        message: "Please fill in all fields",
        isOpen: true,
      });
      store.dispatch("shared/resetNotificationStatus");
      return;
    }
    store.commit("admins/memberStudentsLeadModal", { save: true });
    const { success } = await store.dispatch("admins/createLead", fields.value);
    toggleLeadAddModal(false);
    if (success) {
      setTimeout(async () => {
        for (const key of Object.keys(fields.value)) fields.value[key] = "";
        await store.dispatch("admins/getStudents");
      }, 1500);
    }
  };
  return { leadAddModal, fields, cancel, save, toggleLeadAddModal };
};

export default useLeadAdd;
