<template>
  <div
    class="
      bg-white
      border-r-10
      p-4
      d-flex
      justify-content-center
      align-items-center
      flex-column
      dashboard-admin-manager-path
    "
  >
    <p class="text-primary-0 fw-bold fs-5 text-center">Manage Bright Path</p>
    <div class="mt-3 d-flex flex-column gap-2">
      <button
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          w-100
          d-flex
          alig-items-center
          justify-content-center
          gap-1
        "
        @click="() => toggleModalContent(true)"
      >
        <span class="material-icons"> library_books </span>
        Add new content
      </button>
      <router-link
         :to="{ name: 'admin-library' }"
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          w-100
          d-flex
          alig-items-center
          justify-content-center
          gap-1
        "
        v-if="userAuthenticated.role === 'admin'"
      >
        <span class="material-icons"> import_export </span>
        Download progress
      </router-link
      >
    </div>
  </div>
</template>

<script>
import useContentAdd from "@/modules/admin/composables/MyLibrary/useContentAdd";
import useSharedUI from "../../../../shared/composables/useSharedUI";
export default {
  setup() {
    const { toggleModalContent } = useContentAdd(); 
    const { userAuthenticated } = useSharedUI()
    return {
      toggleModalContent,
      userAuthenticated
    }
  },
}
</script>
