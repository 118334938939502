import { computed, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import selectedImage from "@/shared/helpers/selectedImageHelper";
import isValidateYTUrl from "@/shared/utils/validations/isValidateYTUrl";
import getYtUrlId from "@/shared/utils/getters/getYtUrlId";
import useValidateFields from "@/shared/composables/useValidateFields";
import { v4 as uuidv4 } from "uuid";
import { ref as refDb, set } from "firebase/database";
import database from "@/firebase/config";
import useSharedUI from "@/shared/composables/useSharedUI";
import moment_timezone from "moment-timezone";
import moment from "moment";
const useTeacherAddOrUpdate = () => {
  const store = useStore();
  const changingPhoto = ref(false);
  const teacherModal = computed(
    () => store.getters["admins/memberTeacherModal"].addOrUpdate
  );
  const currentTeacher = computed(() => {
    return store.getters["admins/getTeacher"].data;
  });

  const { sendNotificationNewTeacher } = useSharedUI();

  const fields = ref({
    name: "",
    last_name: "",
    address: "",
    email: "",
    cellphone: null,
    birth: "",
    hire_date: "",
    link_video: "",
    gender: "",
    phone_code_id: "",
    photo: "",
    timezone: "",
    date_now:""
  });

  const listings = computed(() => store.getters["shared/getListings"]);
  const currentTime = ref("");

  const currentStep = ref("1");
  const isShowIframe = ref(false);
  const idIframe = ref("");
  const {
    validateFieldsRequireds,
    resetFieldsValues,
    formatDateFields,
    changeNumberToString,
  } = useValidateFields();
  watchEffect(() => {
    if (fields.value.link_video) {
      const { status, id } = getYtUrlId(fields.value.link_video);
      if (status === "success") {
        isShowIframe.value = isValidateYTUrl(
          "https://www.youtube.com/embed/" + id
        );
        idIframe.value = id;
      }
    } else {
      isShowIframe.value = false;
      idIframe.value = "";
    }
  });

  const toggleTeacherModal = ({ isOpen, status }) => {
    store.commit("admins/setTeacherModal", { isOpen, status });
  };

  const resetForm = () => {
    resetFieldsValues({ currentFields: fields.value });
    currentStep.value = "1";
    urlAvatar.value = "";
    isShowIframe.value = false;
    currentTime.value = "";
    fields.value.days = [];
  };

  const isLoadingTeacherCreation = ref(false);
  const isLoadingTeacherUpdate = ref(false);
  const createOrUpdateTeacher = async (currentAction) => {
    let { newFields, haveFieldsToBeFilled, success } = validateFieldsRequireds({
      currentFields: fields.value,
      deleteFields: false,
      fieldRequireds: ["name", "last_name", "email"],
    });
    if (!haveFieldsToBeFilled && success) {
      newFields = formatDateFields({
        currentFields: newFields,
        fieldsToChange: ["birth", "hire_date"],
      });
      newFields = changeNumberToString({ currentFields: newFields });
      let success = false;
      if (currentAction === "create-step-1" && success) {
        currentStep.value = "2";
      } else if (currentAction === "update-teacher") {
        isLoadingTeacherUpdate.value = true;
        const { id } = currentTeacher.value;
        if (
          typeof newFields.photo === "string" &&
          newFields.photo.startsWith("http")
        ) {
          delete newFields.photo;
        }
        const { success, json } = await store.dispatch("admins/validateZoom", {
          email: newFields.email,
        });
        newFields.account_zoom = json?.message?.personal_meeting_url ?? null;
        if(success){
          newFields.date_now = moment(store.getters['shared/currentTimeTimezone']).format("YYYY-MM-DD");
          const { success } = await store.dispatch("admins/updateTeacher", {
            id,
            data: newFields,
          });
          if (success) {
            toggleTeacherModal({ isOpen: false, status: "" });
            isLoadingTeacherUpdate.value = false;
          }else{
            isLoadingTeacherUpdate.value = false;
          }
        }else{
          isLoadingTeacherUpdate.value = false;
        }
      
      }
    }
  };

  const isLoadingTeacherJobUpdate = ref(false);

  const createTeacherFirebase = () => {
    const idTeacher = uuidv4();
    set(refDb(database, "Teachers/" + idTeacher), {
      status: "offline",
      last_status: "offline",
    });
    return idTeacher;
  };

  const createOrUpdateJob = async (currentAction) => {
   
    let fieldRequireds =  ["name", "last_name", "email"];
   
    fieldRequireds = ["timezone", ...fieldRequireds];

    const timezoneChange = listings.value.timesZones.data.find(item => item.timezone_change === fields.value.timezone_change)
    fields.value.timezone_change = timezoneChange.timezone_change
    fields.value.timezone = timezoneChange.timezone

    let { newFields, haveFieldsToBeFilled, success } = validateFieldsRequireds({
      currentFields: { ...fields.value },
      fieldRequireds,
      customToastMsg: {
        timezone: "Please select a Time Zone",
      },
    });

    console.log(success,haveFieldsToBeFilled)
    if (success && !haveFieldsToBeFilled) {
      newFields = formatDateFields({
        currentFields: newFields,
        fieldsToChange: ["birth", "hire_date"],
      });
      const { days, ...newValues } = newFields;
      if ("create-job" === currentAction) {
        isLoadingTeacherCreation.value = true;
        const { success, json } = await store.dispatch("admins/validateZoom", {
          email: newValues.email,
        });
        if (success) {
          newValues.account_zoom = json?.message?.personal_meeting_url ?? null;
          delete newFields.days;
          const idTeacher = createTeacherFirebase();
          newValues.token_firebase = idTeacher;
          newValues.date_now = moment(store.getters['shared/currentTimeTimezone']).format("YYYY-MM-DD");

  
          const RES_2 = await store.dispatch("admins/createTeacher", newValues);
  
          if (RES_2.success) {
            sendNotificationNewTeacher(
              newValues.name + " " + newValues.last_name
            );
            setTimeout(async () => {
              store.commit("admins/setTeachersBasicInfo", { isLoading: true });
              store.commit("admins/setTeachersAccount", { isLoading: true });
              toggleTeacherModal({ isOpen: false, status: "" });
              isLoadingTeacherCreation.value = false;
              resetForm();
              await store.dispatch("admins/getTeachers");
            }, 1500);
          } else {
            isLoadingTeacherCreation.value = false;
          }
          return;
        }else{
          isLoadingTeacherCreation.value = false
        }
       
      }
    }
  };

  const nextOrCreate = async () => {
    if (currentStep.value === "1") {
      // await createOrUpdateTeacher("create-step-1");
      // await createOrUpdateTeacher("create-step-2");
      await createOrUpdateJob("create-job");


    } else if (currentStep.value === "2") {
      // await createOrUpdateJob("create-job");
    }
  };

  const inputFile = ref();
  const urlAvatar = ref("");

  const changeAvatar = () => {
    fields.value.photo = ""
    inputFile.value.click();
  };

  const onSelectedImage = async ({ target }) => {
    changingPhoto.value = true;
    const { success, file } = selectedImage({ target, image: urlAvatar });

    setTimeout(() => {
      if (success) fields.value.photo = file;
      changingPhoto.value = false;
    },1000)
   
  };

  const selectedDay = ({ target }) => {
    const dayId = target.value;
    if (fields.value.days.includes(dayId))
      fields.value.days = fields.value.days.filter((n) => n !== dayId);
    else fields.value.days.push(dayId);
  };

  const resetCurrentTeacher = () => {
    store.commit("admins/setTeacher", { data: {} });
  };

  watchEffect(() => {
    if (teacherModal.value.status === "add") return;
    const { history_lessons, scheduled_of_jod, id, ...teacher } =
      currentTeacher.value;
    const hasValues = Object.values(teacher)?.filter((v) => v).length > 1;

    if (hasValues) {
      const keysFields = Object.keys(fields.value);
      const valueChanges = ["birth", "hire_date", "role", "cellphone"];
      for (let [key, value] of Object.entries(teacher)) {
        if (key === "code" && value?.id) {
          fields.value["phone_code_id"] = value.id;
        } else if (key === "lastname" && value) {
          fields.value.last_name = value;
        } else if (key === "video" && value) {
          fields.value.link_video = value;
          isShowIframe.value = true;
        } else if (key === "cellphone" && value) {
          fields.value[key] = Number(value.replaceAll(" ", ""));
        } else if ((key === "birth" || key === "hire_date") && value) {
          const new_date = new Date(value);
          new_date.setDate(new_date.getDate() + 1)
          fields.value[key] = new_date
        } else if (key === "photo" && value) {
          urlAvatar.value = value;
          fields.value[key] = value;
        } else if (key === "role" && value) {
          const role_id = listings.value.roles.filter(
            (r) => r.value === value
          )[0]?.id;
          if (role_id) fields.value["role_id"] = role_id;
        } else if (
          keysFields.includes(key) &&
          value &&
          !valueChanges.includes(key)
        ) {
          fields.value[key] = value;
        }
      }
      return;
    }

    resetForm();
  });

  const editProfile = () => {
    toggleTeacherModal({ isOpen: true, status: "edit-profile" });
  };

  const editTeacherJob = () => {
    toggleTeacherModal({ isOpen: true, status: "edit-job" });
  };

  const selectCode = (e) => {
    const code = (e.value.includes("+") ? "" : "+") + e.value;
    const { countrycodes } = store.getters["shared/getListings"];
    const country = countrycodes.data.find((c) => c.dial_code === code);
    if(country) fields.value.phone_code_id = country.id ;
  } 


  return {
    teacherModal,
    toggleTeacherModal,
    selectCode,
    listings,
    changingPhoto,
    currentStep,
    nextOrCreate,
    currentTime,
    fields,
    changeAvatar,
    inputFile,
    onSelectedImage,
    urlAvatar,
    selectedDay,
    isLoadingTeacherCreation,
    isShowIframe,
    editProfile,
    editTeacherJob,
    resetForm,
    resetCurrentTeacher,
    createOrUpdateTeacher,
    isLoadingTeacherUpdate,
    createOrUpdateJob,
    isLoadingTeacherJobUpdate,
    idIframe,
  };
};

export default useTeacherAddOrUpdate;
