<template>
  <div class="bg-primary-5 border-r-10 p-3 dashboard-grid-progress">
    <div>
      <h2 class="text-primary-0 fs-4 fw-bold">
        {{ userAuthenticated.role === "teacher" ? "My performance" : null }}
        {{ userAuthenticated.role === "student" ? "My progress" : null }}
      </h2>
      <div class="d-flex align-items-center gap-3 mt-3 mb-2">
        <div class="position-relative">
          <img class="icon" src="/assets/modules/students/isotipo.png" />
          <p class="fs-1 fw-bold text-white text-percentage">{{ dashboard.totalClasses < 10 ? "0" +
              dashboard.totalClasses : dashboard.totalClasses
          }}</p>

        </div>
        <p class="text-start">
          {{
              userAuthenticated.role === "teacher" ? " Total number of classes taught" : null
          }}

          {{
              userAuthenticated.role === "student" ? " Total number of classes taken" : null
          }}

        </p>
      </div>
      <hr />
      <p class="text-primary-0 fs-5 fw-bold lh-1 mb-3">
        {{
            userAuthenticated.role === "teacher" ? "Classes taught this week" : null
        }}
        {{
            userAuthenticated.role === "student" ? "Classes taken this week" : null
        }}
      </p>
      <progress-week-component />
    </div>
  </div>
</template>

<script>
import ProgressWeekComponent from "./ProgressWeekComponent";
import useSharedUI from "@/shared/composables/useSharedUI";
import useDashboardStudent from "@/modules/students/composables/Dashboard/useDashboard"
import useDashboardTeacher from "@/modules/teachers/composables/Dashboard/useDashboardMain"
export default {
  components: {
    ProgressWeekComponent,
  },
  setup() {
    const { userAuthenticated } = useSharedUI();
    const { dashboard } = userAuthenticated.value.role === "student" ? useDashboardStudent() : useDashboardTeacher()

    return {
      userAuthenticated,
      dashboard,
    }
  },
};
</script>

<style lang="scss" scoped>
.dashboard-grid-progress {
  & .icon {
    max-width: 70px;
  }

  & .text-percentage {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>