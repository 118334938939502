const inserTextIndicators = ({ classIndicators, text, textClasses }) => {
  const indicators = document.querySelector(classIndicators);
  const title = document.createElement("h2");

  if (indicators) {
    textClasses.forEach((c) => {
      title.classList.add(c);
    });

    title.innerText = text;
    indicators.before(title);
  }
};

export default inserTextIndicators;
