<template>
  <div class="bg-white card-teacher text-center w-100 p-3 bl-shadow border-r-10 position-relative d-flex flex-column justify-content-center" :class="isTeacher ? 'border-secondary-1' : '' "
    :style="[total === 1 ? 'max-width: 50%' : '',loadingFavorite ? 'opacity:0.75' : '']" @click="
      (e) => {
        if (isClickable) {
          clickscheduled({ ...data, scheduled_id: scheduledId.id }, scheduledId);
        }
      }
    ">
      <i class="icon-remove-favorite pi pi-minus-circle text-secondary-1 position-absolute top-0 end-0 m-3 pointer" @click="() => {
        // addRemoveFavorite(id)
        toggleDeleteFavorite(id)

      }" v-if="goBookAClass"></i>
    <div class="header-user- w-100 text-center">
      <div class="position-relative text-center user-picture mb-2">
        <avatar :image="photo ? photo : '/assets/images/avatar.png'" shape="circle" />
      </div>
    </div>
    <p class="text-primary-0 fs-6">
      {{ name }}
    </p>
    <!-- <p class="text-primary-0 fs-6" v-if="cardTypes.includes('w-last-name')">
      {{ lastName }}
    </p> -->
    <p v-html="date" class="text-primary-0 fs-6"  v-if="cardTypes.includes('w-date')"></p>
    <div class="p-2 pointer">
      <p class="text-secondary-1 text-hover-secondary-0 fs-6" v-if="cardTypes.includes('w-bookx-class')" role="button"
        @click="goBookAClass">
        Book a class
      </p>
      <p class="text-secondary-1 text-hover-secondary-0 fs-6" v-if="cardTypes.includes('view-profile')" role="button"
        @click="goViewProfile(id)">
        View profile
      </p>
      <p class="text-secondary-1 text-hover-secondary-0 fs-6" v-if="cardTypes.includes('delete-card')" role="button"
        @click="deleteCard">
        {{ deleteText }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useSharedUI from "@/shared/composables/useSharedUI";
import useMyTeachers from "@/modules/students/composables/MyTeachers/useMyTeachers";

export default {
  name: "CardSmallInfoComponent",
  props: {
    cardTypes: {
      type: Array,
      default: [],
    },
    name: {
      type: String,
      default: "",
      required: true,
    },
    date: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    deleteText: {
      type: String,
      default: "Delete",
    },
    goBookAClass: {
      type: Function,
    },
    goViewProfile: {
      type: Function,
    },
    deleteCard: {
      type: Function,
    },
    photo: {
      type: String,
      default: "/assets/images/avatar.png",
    },
    id: {
      type: Number,
      default: 0,
    },
    clickscheduled: {
      type: Function,
    },
    data: {
      type: Object,
    },
    scheduledId: {
      type: Number,
    },
    isTeacher: {
      type: Boolean,
      default: false,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 1,
    }
  },
  setup(props) {
    const { goToBook } = useSharedUI();
     const {
      addRemoveFavorite,
      loadingFavorite,
      toggleDeleteFavorite,
    } = useMyTeachers();
    return {
      goToBook,
      addRemoveFavorite,
      toggleDeleteFavorite,
      loadingFavorite,

    };
  },
};
</script>

<style lang="scss" scoped>
p {
  font-weight: 600;
}
</style>
