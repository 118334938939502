const selectedImage = ({ target, image }) => {
  const file = target.files[0];
  if(file !== undefined){
    const fileType = file.type;
    if (!file || fileType.includes("video")) return { success: false };
    const fr = new FileReader();
    fr.onload = () => (image.value = fr.result);
    fr.readAsDataURL(file);
    return { success: true, file };
  }
  return { success: false }
};

export default selectedImage;
