import { computed } from "vue";
import { useStore } from "vuex";

const useCurrentTeacher = () => {
  const store = useStore();

  const toggleTeacherModal = (isOpen) => {
    store.commit("teachers/toggleTeacherInfoModalIsOpen", isOpen);
  };
  const teacherInfoModalIsOpen = computed(
    () => store.getters["teachers/teacherInfoModalIsOpen"]
  );

  const teacherInfo = computed(() => store.getters["teachers/teacherInfo"]);
  const lastStudent = computed(() => store.getters["teachers/lastStudent"]);
  const hasLastStudent = computed(
    () => Object.values(store.getters["teachers/lastStudent"].data).length > 0
  );
  const classesForDay = computed(
    () => store.getters["teachers/teacherClassesForDay"]
  );

  return {
    toggleTeacherModal,
    teacherInfoModalIsOpen,
    teacherInfo,
    classesForDay,
    lastStudent,
    hasLastStudent,
  };
};
export default useCurrentTeacher;
