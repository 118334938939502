import { useStore } from "vuex";
import {
  computed,
  ref,
  onBeforeMount,
  watchEffect,
  onUpdated,
  onMounted,
  watch,
} from "vue";
import { ref as refDb, set, get, child, onValue } from "firebase/database";
import database from "@/firebase/config";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
const useDashboard = () => {
  const store = useStore();
  const selectOptions = ref(["Weekly", "Monthly"]);
  const currentChart = ref("Weekly");
  const totalTeachersOnnline = ref(0);
  const totalStudentsOnnline = ref(0);
  const studentsData = computed(
    () =>
      store.getters["admins/getDashbordStudents"].data
        .all_students_firebase_tokens
  );
  const techersData = computed(
    () =>
      store.getters["admins/getDashbordTeachers"].data
        .all_teachers_firebase_tokens
  );

  const setInfoOnnlineStudents = () => {
    const dbRef = refDb(database, "Spanish/");
    console.log(studentsData.value);
    onValue(dbRef, async (snapshot) => {
      const online = studentsData.value
        ?.filter((item) => item !== null)
        .filter((item) => snapshot.val()[item]?.status === "online");

      totalStudentsOnnline.value = online?.length || 0;
    });
  };

  watch(studentsData, (newVal, oldVal) => {
    if (Object.values(newVal).length) {
      setInfoOnnlineStudents();
    }
  });

  const setInfoOnnlineTeachers = () => {
    const dbRef = refDb(database, "Teachers/");

    onValue(dbRef, async (snapshot) => {
      const online = techersData.value
        ?.filter((item) => item !== null)
        .filter((item) => snapshot.val()[item]?.status === "online");

      totalTeachersOnnline.value = online?.length || 0;
    });
  };

  const openToast = (message, status) => {
    const notification = { isOpen: true };
    notification.message = message;
    notification.status = status;
    store.commit("shared/uiNotificationStatus", notification);
    store.dispatch("shared/resetNotificationStatus");
  };

  const toPdfClasses = () => {
    const doc = new jsPDF("p", "pt", "letter");
    const scheduled = document.querySelector(
      ".dashboard-admin-total-scheduled"
    );
    const margin = 10;
    const scale =
      (doc.internal.pageSize.width - margin * 2) / scheduled.offsetWidth;
    doc.html(scheduled, {
      x: margin,
      y: margin,
      background: "#fff",
      html2canvas: { scale },
      callback: function (doc) {
        openToast(
          "The charts selected have been exported successfully.",
          "success"
        );
        doc.save("total-classes.pdf");
      },
    });
  };

  onMounted(async () => {
    setInfoOnnlineTeachers();
    setInfoOnnlineStudents();
  });

  const currentChartData = ref({
    Weekly: {
      labels: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      datasets: [
        {
          type: "bar",
          label: "Scheduled",
          typeData: "registered",
          data: [0, 0, 0, 0, 0, 0],
          borderRadius: 20,
          backgroundColor: [
            "#74DAD9",
            "#FAB086",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
          ],
        },
        {
          type: "bar",
          typeData: "attend",
          label: "Effective",
          data: [0, 0, 0, 0, 0, 0],
          borderRadius: 20,
          backgroundColor: [
            "#00A1A0",
            "#EB661A",
            "#00A1A0",
            "#00A1A0",
            "#00A1A0",
            "#00A1A0",
            "#00A1A0",
          ],
        },
      ],
    },
    Monthly: {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          type: "bar",
          label: "Scheduled",
          typeData: "registered",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderRadius: 20,
          backgroundColor: [
            "#74DAD9",
            "#FAB086",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
          ],
        },
        {
          type: "bar",
          typeData: "attend",
          label: "Effective",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderRadius: 20,
          backgroundColor: [
            "#00A1A0",
            "#EB661A",
            "#00A1A0",
            "#00A1A0",
            "#00A1A0",
            "#00A1A0",
            "#00A1A0",
          ],
        },
      ],
    },
  });

  const chartOptions = ref({
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
      datalabels: {
        color: "white",
        textAlign: "center",
        font: {
          weight: "bold",
          size: 16,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        min: 0,
        max: 20,
        stepSize: 5,
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  });

  const students = computed(() => store.getters["admins/getDashbordStudents"]);
  const cardStudents = ref([
    { label: "Lead", value: "count_lead" },
    { label: "Trial", value: "count_trial" },
    { label: "Enrollment", value: "count_enrrollment" },
    { label: "Drops", value: "count_drop" },
  ]);

  const teachers = computed(() => store.getters["admins/getDashbordTeachers"]);
  const cardTeachers = ref([
    {
      label: "Scheduled at least once",
      value: "count_scheduled_at_least_once",
    },
    {
      label: "Without scheduling",
      value: "count_without_scheduling",
    },
    {
      label: "Waiting for class",
      value: "count_waiting_to_schedule",
    },
    {
      label: "In class",
      value: "count_in_class",
    },
  ]);

  const adminInfo = computed(() => {
    return store.getters["admins/adminInfo"];
  });

  const setTotalClasses = (lessons_week, lessons_month) => {
    currentChartData.value.Weekly.datasets.forEach((dataset, index) => {
      Object.entries(lessons_week[dataset.typeData]).forEach(([key, value]) => {
        switch (key) {
          case "Monday":
            currentChartData.value.Weekly.datasets[index].data[0] =
              Array.isArray(value) ? 0 : value;
            break;
          case "Tuesday":
            currentChartData.value.Weekly.datasets[index].data[1] =
              Array.isArray(value) ? 0 : value;
            break;
          case "Wednesday":
            currentChartData.value.Weekly.datasets[index].data[2] =
              Array.isArray(value) ? 0 : value;
            break;
          case "Thursday":
            currentChartData.value.Weekly.datasets[index].data[3] =
              Array.isArray(value) ? 0 : value;
            break;
          case "Friday":
            currentChartData.value.Weekly.datasets[index].data[4] =
              Array.isArray(value) ? 0 : value;
            break;
          case "Saturday":
            currentChartData.value.Weekly.datasets[index].data[5] =
              Array.isArray(value) ? 0 : value;
        }
      });
    });
    currentChartData.value.Monthly.datasets.forEach((dataset, index) => {
      Object.entries(lessons_month[dataset.typeData]).forEach(
        ([key, value]) => {
          switch (key) {
            case "January":
              currentChartData.value.Monthly.datasets[index].data[0] =
                Array.isArray(value) ? 0 : value;
              break;
            case "February":
              currentChartData.value.Monthly.datasets[index].data[1] =
                Array.isArray(value) ? 0 : value;
              break;
            case "March":
              currentChartData.value.Monthly.datasets[index].data[2] =
                Array.isArray(value) ? 0 : value;
              break;
            case "April":
              currentChartData.value.Monthly.datasets[index].data[3] =
                Array.isArray(value) ? 0 : value;
              break;
            case "May":
              currentChartData.value.Monthly.datasets[index].data[4] =
                Array.isArray(value) ? 0 : value;
              break;
            case "June":
              currentChartData.value.Monthly.datasets[index].data[5] =
                Array.isArray(value) ? 0 : value;
              break;
            case "July":
              currentChartData.value.Monthly.datasets[index].data[6] =
                Array.isArray(value) ? 0 : value;
              break;
            case "August":
              currentChartData.value.Monthly.datasets[index].data[7] =
                Array.isArray(value) ? 0 : value;
              break;
            case "September":
              currentChartData.value.Monthly.datasets[index].data[8] =
                Array.isArray(value) ? 0 : value;
              break;
            case "October":
              currentChartData.value.Monthly.datasets[index].data[9] =
                Array.isArray(value) ? 0 : value;
              break;
            case "November":
              currentChartData.value.Monthly.datasets[index].data[10] =
                Array.isArray(value) ? 0 : value;
              break;
            case "December":
              currentChartData.value.Monthly.datasets[index].data[11] =
                Array.isArray(value) ? 0 : value;
              break;
          }
        }
      );
    });
  };

  watchEffect(() => {
    let { total_lessons_month, total_lessons_week } =
      store.getters["admins/adminInfo"];
    if (total_lessons_month !== undefined || total_lessons_week !== undefined)
      setTotalClasses(total_lessons_week, total_lessons_month);
  });

  return {
    toPdfClasses,
    adminInfo,
    selectOptions,
    currentChart,
    currentChartData,
    chartOptions,
    cardStudents,
    students,
    cardTeachers,
    teachers,
    totalStudentsOnnline,
    totalTeachersOnnline,
  };
};

export default useDashboard;
