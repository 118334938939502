<template>
  <div class="dashboard-grid">
    <banner-welcome-component />
    <your-level-component />
    <my-progress-component />

    <scheduled-classes-component />
    <slider-teachers-component  />
    <last-teacher-and-favorites-component/>
    <supercharge-experiencie-component />
  </div>
</template>

<script>
import MyProgressComponent from "@/shared/components/Levels/MyProgressComponent";
import ScheduledClassesComponent from "@/shared/components/Dashboard/ScheduledClassesComponent";

import BannerWelcomeComponent from "@/modules/students/components/Dashboard/BannerWelcomeComponent";
import YourLevelComponent from "@/modules/students/components/Dashboard/YourLevelComponent";
import LastTeacherAndFavoritesComponent from "@/modules/students/components/Dashboard/LastTeacherAndFavoritesComponent";
import SliderTeachersComponent from "@/modules/students/components/Dashboard/SliderTeachersComponent";
import SuperchargeExperiencieComponent from "@/modules/students/components/Dashboard/SuperchargeExperiencieComponente";
import useDashboard from "@/modules/students/composables/Dashboard/useDashboard";
import { onBeforeMount } from "vue";
export default {
  name: "DashboardView",
  components: {
    BannerWelcomeComponent,
    YourLevelComponent,
    MyProgressComponent,
    LastTeacherAndFavoritesComponent,
    ScheduledClassesComponent,
    SuperchargeExperiencieComponent,
    SliderTeachersComponent,
  },
  setup() {
    const { loadServices } = useDashboard();

    onBeforeMount(() => {
      loadServices();
    });
  },
};
</script>

<style lang="scss" scoped>
.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 3rem 16rem;
  gap: 1rem;
  overflow: hidden;
  &-banner-welcome {
    grid-column: 1 / 2;
  }
  &-your-level {
    grid-column: 2 / 4;
  }
  &-progress {
    grid-column: 4 / 5;
    grid-row: 1 / 3;
  }
  &-calendar {
    grid-column: 1 / 4;
    display: grid;
    grid-template-columns: 16rem 1fr;
    gap: 1.5rem;
  }
  &-teachers,
  &-favorites {
    grid-column: 1 / 3;
    @media screen and (max-width: 1200px) {
      grid-column: 1 / 5;
    }
    @media screen and (max-width: 992px) {
      display: block;
    }
  }
  &-experiencie {
    grid-column: 3 / 5;
  }
}
</style>
