const showWeek = (containerClass) => {
  const tableTr = document.querySelectorAll(`.${containerClass} tbody tr`);

  if (tableTr.length > 0) {
    tableTr.forEach((tr) => {
      tr.classList.add("d-none");
    });
    const currentDay = document.querySelector(
      `.${containerClass} .p-datepicker-today`
    );
    currentDay.parentElement.classList.remove("d-none");
  }
};

export default showWeek;
