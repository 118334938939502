<template>
  <skeleton v-if="dashboard.isLoading" borderRadius="10px" height="10rem"
    class="skeleton-dark dashboard-grid-your-level" />
  <div v-else class="
      bg-primary-5
      border-r-10
      p-3
      pb-2
      d-flex
      gap-2
      align-items-center
      justify-content-center
      dashboard-grid-your-level
    ">
    <progress-level-component :title="'Your bright level is'" />
    <div class="w-100 text-center">
      <div class="d-flex justify-content-center align-items-center gap-2 mb-2">
        <circle-progress empty-color="#74DAD9" class="percentage-small" fill-color="#00a1a0" :is-bg-shadow="false"
          :percent="(dashboard.data.total_student_lessons * 100) /
            dashboard.data.total_lessons
            " :show-percent="true" :transition="1000" :border-width="5" :border-bg-width="8" :size="80" />
        <p class="fw-bold">
          {{ dashboard.data.total_student_lessons }}/{{
            dashboard.data.total_lessons
          }}
          Library content
        </p>
      </div>
      <button @click="goToLibrary" class="
          btn
          text-white
          bg-secondary-1 bg-hover-secondary-1
          py-1
          px-4
          bl-shadow
        ">
        Practice
      </button>
    </div>
  </div>
</template>

<script>
import ProgressLevelComponent from "@/shared/components/Levels/ProgressLevelComponent";
import insertPercentage from "@/shared/utils/changeTexts/insertPercentageToProgress";
// import useProgress from "@/shared/composables/Library/useProgress"
import useDashboard from "@/modules/students/composables/Dashboard/useDashboard";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
export default {
  components: {
    ProgressLevelComponent,
  },
  setup() {
    const { dashboard } = useDashboard();
    const router = useRouter();
    const goToLibrary = () => {
      router.push({ name: "student-library" });
    };
    onMounted(() => {
      // insertPercentage({
      //   number:
      //     (dashboard.value.data.total_student_lessons * 100) /
      //     dashboard.value.data.total_lessons,
      // });
    });
    return {
      dashboard,
      insertPercentage,
      goToLibrary,
    };
  },
};
</script>
<style lang="scss" scoped>
.level {
  font-size: 4.5rem;
  line-height: 4.5rem;
}
</style>