const styleCurrentWeekCalendar = () => {
  const currentDay = document.querySelector(
    ".p-datepicker-calendar .p-datepicker-today"
  );

  if (currentDay) {
    currentDay.parentElement.classList.add("current-week");
  }
};

export default styleCurrentWeekCalendar;
