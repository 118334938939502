<template>
  <div
    class="
      supercharge-experiencie
      p-3
      pb-0
      border-r-10
      dashboard-grid-experiencie
    "

    :class="(studentInfo.is_new_student ? 'new_student' : 'not_new_student') 
    && (!teachersFavorites.data.length 
    && !dashboard.data.my_last_teacher &&'grid-5-col')"
  >
    <p class="text-accent-6 bl-text-shadow fw-bold fs-4 lh-sm mb-2 pe-3">
      Supercharge your bright experience
    </p>
    <p class="text-white fs-6 lh-sm">
      You can earn benefits on your Bright Path and keep learning
    </p>
    <img
      src="/assets/modules/students/rocket.png"
      alt="Rocket"
      class="d-block"
    />
    <router-link :to="{ name: 'student-rewards' }">
      <button class="btn bg-accent-6 bg-hover-accent-6 bl-shadow" v-if="userAuthenticated.completed_info">
        Explore rewards
      </button>
    </router-link>
  </div>
</template>

<script>
import useInformation from "@/modules/students/composables/useStudentInformation";
import useDashboard from "@/modules/students/composables/Dashboard/useDashboard";

export default {
  setup(){
    const {  userAuthenticated , studentInfo, teachersFavorites } = useInformation()
    const { dashboard } = useDashboard();
    return {
      userAuthenticated , 
      studentInfo,
      teachersFavorites,
      dashboard
    }
  }
}
</script>

<style lang="scss" scoped>
.supercharge-experiencie {
  background-image: url("/assets/modules/students/card-supercharge.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  & img {
    max-width: 92px;
    width: 100%;
    margin: auto;
    position: relative;
    bottom: -2rem;
    object-fit: contain;
  }

  & button {
    position: absolute;
    bottom: 1rem;
    right: 1.2rem;
  }
}
.grid-5-col {
  grid-column: 1/span 5 !important;
}
</style>